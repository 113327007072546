import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import OrganizationRoutes from "./organization.routes";
import MainLayout from "components/layout/page-containers/main-layout";
import EportalRoutes from "./eportal.routes";
import DashboardRoutes from "./dashboard.routes";
import HostelRoutes from "./hostel.routes";
import AcademicsRoutes from "./academics.routes";
import AdmissionRoutes from "./admissions.routes";
import FinanceRoutes from "./finance.routes";
import AdministrationRoutes from "./administration.routes";
import CareerRoutes from "./career.routes";

const PrivateRoutes: FC = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path="/organization/*" Component={OrganizationRoutes} />
        <Route path="/dashboard/*" Component={DashboardRoutes} />
        <Route path="/e-portal/*" Component={EportalRoutes} />
        <Route path="/hostel/*" Component={HostelRoutes} />
        <Route path="/academics/*" Component={AcademicsRoutes} />
        <Route path="/admissions/*" Component={AdmissionRoutes} />
        <Route path="/finance/*" Component={FinanceRoutes} />
        <Route
          path="/system-administration/*"
          Component={AdministrationRoutes}
        />
        <Route path="/career/*" Component={CareerRoutes} />
      </Routes>
    </MainLayout>
  );
};

export default PrivateRoutes;
