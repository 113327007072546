import styled from "styled-components";

export const JobListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
  .flex {
    display: flex;
    gap: 2rem;
  }
`;

export const JobListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 490px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;

export const JobListingLeft = styled.div`
  background-color: var(--white-color);
  width: 30%;
  padding: 16px;
  border-radius: 20px;

  .search-input {
    border-radius: 40px;
    background-color: var(--header-utility-icons-bg);
    height: 50px;
    width: 248px;
    display: flex;
    align-items: center;
    justify-content: center;

    .search-icon {
      width: 19.45px;
      height: 20.82px;
    }

    input {
      color: var(--metalic-gray);
      margin-left: 5px;
      padding-left: 5px;
    }
  }
  .search-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    color: var(--black-text);
  }
  /* .select-option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
  } */
  .job-categories {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-block: 2rem;
  }
  .option {
    margin: 10px 10px 0 0;
    color: var(--black-text);
    input {
      margin-right: 8px;
    }
  }
`;
export const JobListingRight = styled.div`
  width: 70%;
  padding: 16px;

  .left-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid var(--gray-light);
    padding-bottom: 10px;
  }
  .main-heading {
    color: var(--primary);
    font-size: 28px;
    font-weight: 500;
  }
  .select-field {
    width: 150px;
    height: 36px;
    border: 1px solid var(--gray-light);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    select {
      color: var(--metalic-gray);
    }
  }
  .job-description-main {
    background-color: var(--white-color);
    padding: 16px;
    border-radius: 20px;
    margin-top: 2rem;
  }
  .description-title {
    color: var(--primary);
    font-size: 22px;
    font-weight: 500;
  }
  .job-type {
    color: var(--lightgreen-medium);
    background-color: var(--lightgreen-shadow);
    width: 66.99px;
    font-size: 13px;
    padding: 0px 4px;
    border-radius: 5px;
  }
  .title-main {
    display: flex;
    justify-content: space-between;
  }
  .job-description {
    font-size: 15px;
    padding-block: 27px;
    color: var(--black-text);
  }
  .department {
    color: var(--black-text);
    font-size: 16px;
    font-weight: 700;
  }
  .office {
    color: var(--modal-grey);
    font-size: 16px;
    margin-left: 10px;
    font-weight: 400;
  }
  .job-title {
    font-size: 14px;
    color: var(--lightgray-medium);
  }
  .job-data {
    font-size: 14px;
    color: var(--lightgray-light);
  }
  .detail {
    display: flex;
    gap: 5px;
  }
  .job-details {
    display: flex;
    gap: 10px;
    padding-block: 1rem;
  }
  .submit-buttons {
    display: flex;
    justify-content: end;
  }
`;
