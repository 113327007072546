export enum siteRoutes {
  superAdminLogin = "/",
  adminLogin = "/admin/login",
  forgetPassword = "/admin/login/forget-password",
  codeVerification = "/admin/login/verification-code",
  createOrganization = "/private/organization/org/create-organization",
  organizationListing = "/private/organization/org/organization-listing",
  viewOrganization = "/private/organization/org/view-organization",
  createAsset = "/private/organization/assets-management/create-asset",
  assetsListing = "/private/organization/assets-management/assets-listing",
  createBuilding = "/private/organization/buildings-management/create-building",
  buildingListing = "/private/organization/buildings-management/buildings-listing",
  createProgram = "/private/organization/programs/create-program",
  programListing = "/private/organization/programs/programs-listing",
  createOrgStructure = "/private/organization/org-structure/create-organizational-structure",
  orgStructureListing = "/private/organization/org-structure/organizational-structure-listing",
  createStructureType = "/private/organization/structure-types/create-structure-type",
  structureTypeListing = "/private/organization/structure-types/structure-types-listing",
  resultTypeListing = "/private/organization/result-type/result-type-listing",
  resultTypeCreate = "/private/organization/result-type/result-type-create",
  superAdminDashboard = "/private/dashboard/super-admin-dashboard",
  adminDashboard = "/private/dashboard/admin-dashboard",
  ePortalLogin = "/e-portal/login",
  ePortalRegister = "/e-portal/register",
  ePortalResetPassword = "/e-portal/reset-password",
  ePortalDashboard = "/private/e-portal/e-portal-dashboard",
  ePortalEditProfile = "/private/e-portal/user-profile/edit-profile",
  ePortalChallanListing = "/private/e-portal/challan-listing",
  ePortalMyVouchers = "/private/e-portal/my-vouchers",
  ePortalShortCoursesListing = "/private/e-portal/short-courses-listing",
  eportalHostelPortal = "/private/e-portal/eportal-hostel-portal",
  eportalAdmissionsListing = "/private/e-portal/application-list/eportal-application-listing",
  createEportalAdmissionList = "/private/e-portal/application-list/create-eportal-application-list",
  createHostel = "/private/hostel/hostel-management/create-hostel",
  hostelListing = "/private/hostel/hostel-management/hostel-listing",
  createHostelRoom = "/private/hostel/hostel-rooms-management/create-hostel-room",
  hostelRoomsListing = "/private/hostel/hostel-rooms-management/hostel-rooms-listing",
  applicantListing = "/private/hostel/applicants-management/applicant-listing",
  createApplicant = "/private/hostel/applicants-management/create-applicant",
  createHostelMerit = "/private/hostel/merit-management/create-merit",
  hostelMeritListing = "/private/hostel/merit-management/merit-listing",
  hostelSessionListing = "/private/hostel/session-management/hostel-session-listing",
  createHostelSession = "/private/hostel/session-management/create-hostel-session",
  hostelFinanceManagement = "/private/hostel/finance-management/hostel-finance-management",
  appliedApplicant = "/private/hostel/applied-applicant-management/applied-applicant",
  applicantEnrollment = "/private/hostel/applicant-enrollment-management/applicant-enrollment",
  registeredApplicant = "/private/hostel/registered-applicant-management/registered-applicant",
  hostelApplicantMeritList = "/private/hostel/hostel-applicant-merit-list-management/hostel-applicant-merit-list",
  academicSessionListing = "/private/academics/academic-session-management/academic-session-listing",
  createAdmissionSession = "/private/admissions/admission-session/create-admission-session",
  viewEportalProfile = "/private/e-portal/user-profile/view-profile",
  admissionSessionListing = "/private/admissions/admission-session/admission-session-listing",
  admissionCampaignListing = "/private/admissions/admission-campaign/admission-campaigns-listing",
  createAdmissionCampaign = "/private/admissions/admission-campaign/create-admission-campaign",
  admissionStudentListing = "/private/admissions/student-admissions/admission-students-listing",
  admissionApplicantsListing = "/private/admissions/applicant-admissions/admission-applicants-listing",
  editAdmissionDocument = "/private/admissions/admission-documents-management/edit-admission-document",
  admissionDocumentListing = "/private/admissions/admission-documents-management/admission-documents-listing",
  admissionTestTypesListing = "/private/admissions/admission-test-types/admission-test-types-listing",
  createAdmissionTestType = "/private/admissions/admission-test-types/create-admission-test-type",
  studentRegListing = "/private/admissions/student-registration/student-registration-listing",
  admissionMeritListing = "/private/admissions/admission-merit-list/admission-merit-listing",
  admissionOfferLetterListing = "/private/admissions/admission-offer-letter/admission-offer-letters-listing",
  createAdmissionOfferLetter = "/private/admissions/admission-offer-letter/create-admission-offer-letter",
  editAdmissionBoard = "/private/admissions/admission-board/edit-admission-board",
  editAdmissionCertificate = "/private/admissions/admission-certificate/edit-admission-certificate",
  admissionManageDocumentsMasterListing = "/private/admissions/admission-document-master/admission-documents-master-listing",
  admissioneligibilityTemplateHeadersListing = "/private/admissions/admission-eligibility-templates/admission-eligibility-template-headers-listing",
  createAdmissioneligibilityTemplateHeaders = "/private/admissions/admission-eligibility-templates/create-admission-eligibility-template-header",
  admissioneligibilityTemplateBodiesListing = "/private/admissions/admission-eligibility-templates/admission-eligibility-template-bodies-listing",
  createAdmissioneligibilityTemplateBody = "/private/admissions/admission-eligibility-templates/create-admission-eligibility-template-body",
  admissionQuotasListing = "/private/admissions/admission-quotas/admission-quotas-listing",
  createAdmissionQuotas = "/private/admissions/admission-quotas/create-admission-quotas",
  certificateLinkListing = "/private/organization/result-type/certificate-link-listing",
  certificateLinkCreate = "/private/organization/result-type/certificate-link-create",
  roleManagementListing = "/private/organization/roll-management/role-management-listing",
  rollManagementCreate = "/private/organization/roll-management/roll-management-create",
  boardManagementListing = "/private/organization/board-management/board-management-listing",
  boardManagementCreate = "/private/organization/board-management/board-management-create",
  subjectManagementListing = "/private/organization/subject-management/subject-management-listing",
  subjectManagementCreate = "/private/organization/subject-management/subject-management-create",
  certificateManagementListing = "/private/organization/certificate-management/certificate-management-listing",
  certificateManagementCreate = "/private/organization/certificate-management/certificate-management-create",
  superAdminManagementListing = "/private/organization/super-admin-management/super-admin-management-listing",
  superAdminManagementCreate = "/private/organization/super-admin-management/super-admin-management-create",
  superRoleManagementListing = "/private/organization/super-role-management/super-role-management-listing",
  superRoleManagementCreate = "/private/organization/super-role-management/super-role-management-create",
  permissionManagementListing = "/private/organization/permission-management/permission-management-listing",
  permissionManagementCreate = "/private/organization/permission-management/permission-management-create",
  moduleManagement = "/private/organization/module-management",
  linkedSpecializationListing = "/private/organization/programs/linked-specialization-listing",
  createLinkedSpecialization = "/private/organization/programs/create-linked-specialization",
  linkedProgramEntryTestListing = "/private/organization/programs/linked-program-entry-test-listing",
  createLinkedProgramEntryTest = "/private/organization/programs/create-linked-program-entry-test",
  academicSessionOrganizationListing = "/private/organization/academic-session-organization/academic-session-organization-listing",
  createAcademicSessionOrganization = "/private/organization/academic-session-organization/create-academic-session-organization",
  admissionProgramListing = "/private/admissions/admission-program/admission-program-listing",
  admissionSubjectManagementListing = "/private/admissions/admission-subject-mangement/admission-subject-management-listing",
  createAdmissionSubjectManagement = "/private/admissions/admission-subject-mangement/create-admission-subject-management",
  admissionBoardManagementListing = "/private/admissions/admission-board-management/admission-board-management-listing",
  createAdmissionBoardManagement = "/private/admissions/admission-board-management/create-admission-board-management",
  admissionCertificatemanagementListing = "/private/admissions/admission-certificate-management/admission-certificate-management-listing",
  createAdmissionCertificateManagement = "/private/admissions/admission-certificate-management/create-admission-certificate-management",
  meritKeysListing = "/private/admissions/merit-keys-management/merit-keys-listing",
  createMeritKeys = "/private/admissions/merit-keys-management/create-merit-keys",
  meritListFormulaListing = "/private/admissions/merit-list-formula-management/merit-list-formula-listing",
  createMeritListFormula = "/private/admissions/merit-list-formula-management/create-merit-list-formula",
  // linkedProgramAdmissionListing = '/private/admission/linked-program-admission/linked-program-admission-listing',
  majorCategoriesListing = "/private/organization/assets-management/major-categories-listing",
  CreateMajorCategories = "/private/organization/assets-management/create-major-categories",
  minorCategoriesListing = "/private/organization/assets-management/minor-categories-listing",
  createMinorCategories = "/private/organization/assets-management/create-minor-categories",
  roomsListing = "/private/organization/buildings-management/rooms-listing",
  createRooms = "/private/organization/buildings-management/create-rooms",
  quotasAdmissionListing = "/private/admissions/qoutas-list-management/AdmissionQuota-list",
  createQuotasAdmissionList = "/private/admissions/qoutas-list-management/AdmissionQuota-create",
  manageMeritListListing = "/private/admissions/manage-merit-list/ManageMeritList-list",
  createAdmissionManageMeritList = "/private/admissions/manage-merit-list/ManageMeritList-create",
  qoutasListProgramListing = "/private/admissions/qoutas-list-management/quota-list-program-listing",
  eligibilityTemplatesListing = "/private/admissions/eligibility-templates-management/ProgramLinkTemplate-list",
  eligibilityTemplatesCreate = "/private/admissions/eligibility-templates-management/ProgramLinkTemplate-create",
  programLinkMeritList = "/private/admissions/program-link-merit-management/ProgramLinkMerit-list",
  createProgramLinkMeritList = "/private/admissions/program-link-merit-management/ProgramLinkMerit-create",
  financeDashboard = "/private/finance/finance-dashboard",
  systemUsersListing = "/private/system-administration/system-users/system-users-listing",
  createSystemUsers = "/private/system-administration/system-users/create-system-users",
  systemMenusListing = "/private/system-administration/menus/system-menus-listing",
  createSystemMenus = "/private/system-administration/menus/create-system-menus",
  unregisteredMenusListing = "/private/system-administration/unregistered-menus/unregistered-menus-listing",
  userAuditListing = "/private/system-administration/user-audit-listing",
  workflowListing = "/private/system-administration/workflow/workflow-listing",
  createWorkflow = "/private/system-administration/workflow/create-workflow",
  eportalCareersListing = "/private/e-portal/e-portal-career/eportal-career-listing",
  createEportalCareer = "/private/e-portal/e-portal-career/create-eportal-career",
  systemLogsListing = "/private/system-administration/system-logs/system-logs-listing",
  studentFinancialClearanceListing = "/private/finance/student-financial-clearance-listing",
  checkFinancialChallanStatus = "/private/finance/check-financial-challan-status",
  fineSlotsListing = "/private/finance/fine-slots/fine-slots-listing",
  createFineSlot = "/private/finance/fine-slots/create-fine-slot",
  templateProgramAccountCodeListing = "/private/finance/template-program-account-code-listing",
  voucherTypesListing = "/private/finance/voucher-types/voucher-types-listing",
  createVoucherType = "/private/finance/voucher-types/create-voucher-type",
  createFinanceApplications = "/private/finance/finance-applications/create-finance-application",
  financeApplicationsListing = "/private/finance/finance-applications/finance-applications-listing",
  applicationTemplateLinkListing = "/private/finance/application-template-link/application-template-link-listing",
  createApplicationTemplateLink = "/private/finance/application-template-link/create-application-template-link",
  createVoucherTemplateHeader = "/private/finance/voucher-template-header/create-voucher-template-header",
  voucherTemplateHeaderListing = "/private/finance/voucher-template-header/voucher-template-header-listing",
  createBank = "/private/finance/banks/create-bank",
  banksListing = "/private/finance/banks/banks-listing",
  bankTransactionLogsListing = "/private/finance/bank-transactions-logs-listing",
  bankScrollLogsListing = "/private/finance/bank-scroll-logs-listing",
  createVoucherParticular = "/private/finance/voucher-particulars/create-voucher-particular",
  voucherParticularListing = "/private/finance/voucher-particulars/voucher-particulars-listing",
  masterBookListing = "/private/finance/master-book/master-book-listing",
  masterBookTransactionDetail = "/private/finance/master-book/master-book-transaction-detail",
  showMasterBookTransactionDetail = "/private/finance/master-book/show-master-book-transaction-detail",
  eportalAppliedJobListing = "/private/e-portal/e-portal-jobs/eportal-applied-jobs-listing",
  eportalCarrerProfile = "/private/e-portal/career-profile/career-profile-listing",
  careerDesignationListing = "/private/career/designation-management/career-designation-listing",
  createCareerDesignation = "/private/career/designation-management/create-career-designation",
  careerAdvertisementListing = "/private/career/advertisement-management/career-advertisement-listing",
  createCareerAdvertisement = "/private/career/advertisement-management/create-career-advertisement",
  careerPostTemplateListing = "/private/career/post-template-management/post-template-listing",
  createCareerPostTemplate = "/private/career/post-template-management/create-post-template",
  careerPostListing = "/private/career/post-management/career-post-listing",
  createCareerPost = "/private/career/post-management/create-career-post",
  careerApplicantListing = "/private/career/applicant-management/career-applicant-listing",
  voucherTemplateBodiesListing = "/private/finance/voucher-template-bodies/voucher-template-bodies-listing",
  financeInstallmentListing = "/private/finance/installment-management/installment-listing",
  createFinanceInstallment = "/private/finance/installment-management/create-installment",
  installmentSlotListing = "/private/finance/installment-slot-management/installment-slot-listing",
  createInstallmentSlot = "/private/finance/installment-slot-management/create-installment-slot",
  slotsInstallmentParticulars = "/private/finance/installment-slot-management/slots-installment-particulars",
}
