import { FC, useEffect, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg, SmallUploadSvg } from "assets/images/common/svgs";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { AddEntryTestDTO } from "utils/helpers/models/e-portal/add-entry-test.dto";
import useEportal from "containers/private/e-portal/useHooks";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";

interface AddEntryTestProps {
  setOpen: Function;
  id?: number;
}

const AddRefrence: FC<AddEntryTestProps> = ({ setOpen }) => {
  const [formData, setFormData] = useState<AddEntryTestDTO>(
    new AddEntryTestDTO()
  );
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<AddEntryTestDTO>();
  const navigate = useNavigate();
  const [entryTestTypes, setEntryTestTypes] = useState<any[]>([]);

  const { createEntryTest, getEntryTestTypesByUser, updateEntryTest } =
    useEportal();
  const { state } = useLocation();

  const onSubmit = (data: AddEntryTestDTO, addMore?: boolean) => {
    if (!formData.result_document) {
      warningToaster(warningMessages.documentRequiredMsg);
      return;
    } else if (formData.obtained_marks > formData.total_marks) {
      warningToaster(
        "Obtained marks must be less than or equal to total marks"
      );
      return;
    }
    const form_data = new FormData();
    for (let key in data) {
      form_data.append(key, data[key as keyof AddEntryTestDTO]);
    }

    if (state?.entryTest) {
      updateEntryTest(state?.entryTest?.id, form_data, handleCloseModal);
    } else {
      createEntryTest(form_data, handleCloseModal, addMore, resetForm);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
    trigger(name);
  };

  const resetForm = () => {
    setFormData({ ...new AddEntryTestDTO() });
    for (let key in getValues()) {
      setValue(key as keyof AddEntryTestDTO, "");
    }
  };

  const handleFileUpload = (event: any) => {
    const { name, files } = event.target;
    const file = files[0];
    setValue(name, file);
    setFormData({ ...formData, [name]: URL.createObjectURL(file) });
    trigger([name]);
  };

  useEffect(() => {
    getEntryTestTypesByUser(setEntryTestTypes);
    if (state?.entryTest) {
      if (state?.entryTest) {
        const { entryTest } = state;
        for (let key in formData) {
          setValue(key as keyof AddEntryTestDTO, entryTest[key]);
        }

        setFormData({ ...getValues() });
      }
    }
  }, []);

  const handleCloseModal = () => {
    if (state?.entryTest)
      navigate(siteRoutes.ePortalEditProfile, { state: {} });
    setOpen(false);
  };

  const onResultAwaitingChange = (event: any) => {
    const { checked } = event.target;
    const result_awaiting = checked ? 1 : 0;
    setValue("result_awaiting" as keyof AddEntryTestDTO, result_awaiting);
    setFormData({
      ...formData,
      result_awaiting,
      total_marks: 0,
      obtained_marks: 0,
    });
  };

  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>References </span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Reference Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Your Reference Name"
                    />
                  </div>
                  <FormErrorMessage error={errors.test_id} />
                </div>
              </div>
              <div className="input-field">
                <label>Designation</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Your Reference Designation"
                    />
                  </div>
                  <FormErrorMessage error={errors.roll_number} />
                </div>
              </div>
              <div className="input-field">
                <label>Organization</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Your Reference Organization"
                    />
                  </div>
                  <FormErrorMessage error={errors.test_date} />
                </div>
              </div>
              <div className="input-field">
                <label>Relationship</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Your  Relationship with Reference"
                    />
                  </div>
                  <FormErrorMessage error={errors.test_id} />
                </div>
              </div>
              <div className="input-field">
                <label>Contact</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Your Reference Contact"
                    />
                  </div>
                  <FormErrorMessage error={errors.roll_number} />
                </div>
              </div>
              <div className="input-field">
                <label>Email</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Your Reference Email"
                    />
                  </div>
                  <FormErrorMessage error={errors.test_date} />
                </div>
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="reset">
                  Reset
                </button>

                <button className="lg-rounded-btn black" type="submit">
                  Save & Add More
                </button>

                <button className="lg-rounded-btn" type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default AddRefrence;
