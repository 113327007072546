import { FC, Fragment, useState } from "react";
import { ShowTransactionDetailListingSection, ShowTransactionDetailMain, ShowTransactionDetailTop, TransactionDetailSection, TransactionDropdown } from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { DeleteTableSvg, DownArrowLightgrayMediumSvg, EditTableSvg, ExcelSvg, PdfSvg, SearchFieldSvg } from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import useStore from "hooks/useStore";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

interface VoucherTypesListingProps { }

const ShowTransactionDetail: FC<VoucherTypesListingProps> = ({ }) => {
    const navigate = useNavigate();
    const [showTransactionDropdown, setShowTransactionDropdown] = useState<boolean>(false);
    const columns: string[] = [
        "Title",
        "Account Code",
        "Action",
    ]

    const { hasAccess } = useStore();

    const goToCreateVoucher = () => {
        navigate(siteRoutes.createVoucherType);
    }

    const toggleTransactionDropdown = () => {
        setShowTransactionDropdown(!showTransactionDropdown);
    }
    return (
        <ShowTransactionDetailMain>
            <ShowTransactionDetailTop>
                <div className="left">
                    <span className="page-heading">Show Transaction Details</span>
                    <Breadcrumb />
                </div>
            </ShowTransactionDetailTop>

            <ShowTransactionDetailListingSection className="content-radius-shadow">
                <TransactionDetailSection>
                    <TransactionDropdown showTransactionDropdown={showTransactionDropdown}>
                        <span className="filter-heading">Application: BWP-F24-201-29643, Challan: 2431159088</span>
                        <span className="dropdown-arrow cp" onClick={toggleTransactionDropdown}>
                            <DownArrowLightgrayMediumSvg className="icon" />
                        </span>
                    </TransactionDropdown>
                    {showTransactionDropdown ? <div className="dropdown-content">
                        <div className="total-amount">
                            <div className="total-amount-box">
                                <div className="title">Total Amount</div>
                                <div className="amount">1000</div>
                            </div>
                        </div>
                    </div> : ''}
                </TransactionDetailSection>
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        <div className="export-btn">
                            <span>
                                <PdfSvg className="icon" />
                            </span>
                            <span className="text">PDF</span>
                        </div>
                        <div className="export-btn">
                            <span>
                                <ExcelSvg className="icon" />
                            </span>
                            <span className="text">Excel</span>
                        </div>
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => {
                                return <tr key={index}>
                                    <td>BS & M.Sc. Admission Processing Fees</td>
                                    <td>01</td>
                                    <td>
                                        <div className="table-action-icons">
                                            <div className="action-icon">
                                                <button className="md-action-btn">Audit Trail</button>
                                            </div>
                                            <div className="action-icon">
                                                <DeleteTableSvg />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    <Pagination page={1} onPageChange={e => console.log(e)} per_page={10} totalRecords={40} />
                </Fragment>
            </ShowTransactionDetailListingSection>
        </ShowTransactionDetailMain>
    )
}

export default ShowTransactionDetail;