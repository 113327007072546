import { FC, Fragment, useState } from "react";
import {
  SlotsParticularListingSection,
  SlotsParticularListingMain,
  SlotsParticularListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import EditTemplateBody from "./components/edit-particular-amount";
import AddParticularToSlot from "./components/add-particulars-to-slot";
interface VoucherTemplateHeadersListingProps {}

const SlotsParticular: FC<VoucherTemplateHeadersListingProps> = ({}) => {
  const columns: string[] = ["Particular", "Amount", "Action"];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const [openAddParticularToChallan, setOpenAddParticularChallan] =
    useState<boolean>(false);

  const [openEditTemplateBody, setOpenEditTemplateBody] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  return (
    <SlotsParticularListingMain>
      <SlotsParticularListingTop>
        <div className="left">
          <span className="page-heading">
            Manage Slots Installment Particular
          </span>
          <Breadcrumb />
        </div>
        <div className="right">
          <div className="create-fine-slot-btn">
            <button
              className="lg-rounded-btn"
              onClick={() => setOpenAddParticularChallan(true)}
            >
              + Add Voucher Particular
            </button>
          </div>
        </div>
      </SlotsParticularListingTop>

      <SlotsParticularListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Admission Processing Fee</td>
                <td>1,500</td>

                <td>
                  <div className="table-action-icons">
                    <div
                      className="action-icon"
                      onClick={() => setOpenEditTemplateBody(true)}
                    >
                      <EditTableSvg />
                    </div>

                    <div className="action-icon">
                      <DeleteTableSvg />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
        {openAddParticularToChallan ? (
          <AddParticularToSlot setOpen={setOpenAddParticularChallan} />
        ) : (
          ""
        )}
        {openEditTemplateBody ? (
          <EditTemplateBody setOpen={setOpenEditTemplateBody} />
        ) : (
          ""
        )}
      </SlotsParticularListingSection>
    </SlotsParticularListingMain>
  );
};

export default SlotsParticular;
