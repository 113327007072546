import { FC, Fragment, useState } from "react";
import {
  BlackArrowSvg,
  CallTableSvg,
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  DownloadYellowSvg,
  EditTableSvg,
  ExcelSvg,
  MessageTableSvg,
  PdfSvg,
  SearchFieldSvg,
  StopActionSvg,
  TabPrimaryActionMenu,
  UserActionSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  UsersListingMain,
  UsersListingSection,
  UsersListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useComponentVisible from "hooks/click-outside";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const UsersListing: FC = () => {
  const [data, setData] = useState<any[]>([
    { isExpanded: false },
    { isExpanded: false },
    { isExpanded: false },
    { isExpanded: false },
  ]);
  const { hasAccess } = useStore();
  const [search, setSearch] = useState("");
  const {
    isComponentVisible: showDropdownMenu,
    setIsComponentVisible: setShowDropdownMenu,
    ref: dropdownMenuRef,
  } = useComponentVisible(false);
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "",
    "CNIC",
    "Name",
    "Username",
    "Department",
    "Designation",
    "Scale",
  ];

  const toggleRowExpand = (index: number) => {
    const dat = [...data];
    dat[index].isExpanded = !dat[index].isExpanded;
    setData([...dat]);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  const toggleDropdownMenu = () => {
    setShowDropdownMenu(!showDropdownMenu);
  };

  const navigate = useNavigate();

  const goToCreateUSer = () => {
    navigate(siteRoutes.createSystemUsers);
  };

  return (
    <UsersListingMain>
      <UsersListingTop>
        <div className="left">
          <span className="page-heading">Users</span>
          <Breadcrumb />
        </div>
        <div className="right">
          <div className="create-org-btn">
            <button className="lg-rounded-btn" onClick={goToCreateUSer}>
              + Add New
            </button>
          </div>
        </div>
      </UsersListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label> User Type</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Role</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Apply Filters</button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <UsersListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadStudentRegPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadStudentRegExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              // onKeyUp={handleSearch}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{item.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>
                        <div>31303-1234567-9</div>
                      </td>
                      <td>
                        <div className="mw-150">BWP-5425-54</div>
                      </td>
                      <td>
                        <div className="mw-100">Jessica Baker</div>
                      </td>
                      <td>
                        <div className="mw-150">31203-3567057-2</div>
                      </td>
                      <td>
                        <div className="mw-150">Fall 2024</div>
                      </td>
                      <td>
                        <div className="mw-150">BS Computer Science</div>
                      </td>
                    </tr>

                    {item.isExpanded && (
                      <tr>
                        <td></td>
                        <td colSpan={7}>
                          <div className="expanded-content">
                            <div className="particular-info">
                              <span className="title">User Id</span>
                              <span className="info">--</span>
                            </div>
                            <div className="particular-info">
                              <span className="title">User Type</span>
                              <span className="info">--</span>
                            </div>
                            <div className="particular-info">
                              <span className="title">Access Depart</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Role</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Father Name</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Gender</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">City</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Status</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Date Of Birth</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Created Date</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>

                            <div className="particular-info">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-icons">
                                  {hasAccess(
                                    sitePermissions.studentRegEdit
                                  ) && (
                                    <div className="action-icon">
                                      <EditTableSvg />
                                    </div>
                                  )}

                                  <div className="action-icon">
                                    <CallTableSvg />
                                  </div>

                                  <div className="action-icon">
                                    <MessageTableSvg />
                                  </div>

                                  <div className="action-icon">
                                    <DeleteTableSvg />
                                  </div>
                                  <div className="action-icon">
                                    <UserActionSvg />
                                  </div>
                                  <div className="action-icon">
                                    <StopActionSvg />
                                  </div>
                                  <div className="action-icon">
                                    <DownloadYellowSvg />
                                  </div>
                                  <div className="action-icon">
                                    <BlackArrowSvg />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination
            onPageChange={(page: any) => console.log(page)}
            {...pagination}
          />
        </Fragment>
      </UsersListingSection>
    </UsersListingMain>
  );
};

export default UsersListing;
