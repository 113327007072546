import AcademicSessionListing from "containers/private/academics/academic-session/listing";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

const AcademicsRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/academic-session-management/academic-session-listing" Component={AcademicSessionListing} />
        </Routes>
    )
}

export default AcademicsRoutes;