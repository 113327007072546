import { FC, useState, useEffect } from "react";
import { CreateUserSection, CreateUserMain, CreateUserTop } from "./style";

import FormErrorMessage from "components/particles/forms/form-error-message";
import Breadcrumb from "components/particles/breadcrumb";

import { AddMeritListAutomationDTO } from "utils/helpers/models/admissions/add-merit-list-automation.dto";
import { SmallUploadSvg } from "assets/images/common/svgs";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";

const CreateSystemUser: FC = () => {
  const handleFileUpload = (event: any) => {
    const { name, files } = event.target;
    const file = files[0];
  };

  return (
    <CreateUserMain>
      <CreateUserTop>
        <div className="left">
          <span className="page-heading">Add User</span>
          <Breadcrumb />
        </div>
        <div className="right"></div>
      </CreateUserTop>

      <CreateUserSection className="p-custom-scrollbar-8">
        <form>
          <div className="common-fields">
            <div className="input-field ">
              <label>First Name</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Last Name</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>User Name</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field">
              <label>CNIC</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="number" />
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>Department</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Department</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>Designation </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Designation</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field">
              <label>Scale </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Scale</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>User Type </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select User Type</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Employ ID</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field">
              <label>User Campus </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select User Campus</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>User Access Department </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select User Access Department </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>User Role</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Role</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field ">
              <label>Phone Number</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="number" />
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Email</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="email" />
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Father Name</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field">
              <label>Date of Birth.</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="date" />
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>City</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select City</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>District</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select District</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="common-fields">
            <div className="radio-field">
              <label htmlFor="no">Status</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="radio" id="yes" />
                  <label htmlFor="yes">Active</label>
                </div>
                <div className="field">
                  <input type="radio" id="no" />
                  <label htmlFor="no">De-active</label>
                </div>
              </div>
            </div>
          </div>
          <div className="upload-field">
            <label htmlFor="domicile_certificate">Profile Picture</label>
            <label className="field-wrapper" htmlFor="domicile_certificate">
              <div className="file-name-section">
                <div className="inner-content">
                  <div className="upload-text">
                    <div className="upload-icon">
                      <SmallUploadSvg className="icon" />
                    </div>
                    <span className="text">Upload Profile Picture</span>
                  </div>
                  <div className="upload-restrictions">
                    Select a 300x300 jpg image with maximum size of 400 KB
                  </div>
                </div>
              </div>
              <div className="uploaded-image domicile">
                <img src={squareAvatar} alt="" />
              </div>
              <input
                type="file"
                id="domicile_certificate"
                className="d-none"
                onChange={handleFileUpload}
              />
            </label>
          </div>

          <div className="action-buttons">
            <div className="buttons">
              <button className="lg-rounded-btn gray" type="button">
                Reset
              </button>
              <button className="lg-rounded-btn black">Save & Add more</button>
              <button className="lg-rounded-btn">Save & Exit</button>
            </div>
          </div>
        </form>
      </CreateUserSection>
    </CreateUserMain>
  );
};

export default CreateSystemUser;
