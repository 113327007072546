import { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  EditEPortalProfileMain,
  EditEPortalProfileTopSection,
  EditProfileContentSection,
  FormSection,
  FormStepper,
  Step1,
  Step2,
  Step3,
  Step4,
  TableWrapper,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AdditionalInfoSvg,
  ExamEntrySvg,
  GuardiansSvg,
  PersonalInfoSvg,
} from "assets/images/e-portal/svgs";
import dummyAvatar from "assets/images/common/others/dummy-avatar.png";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import Stepper from "components/particles/forms/stepper";
import {
  DeleteTableSvg,
  DownloadPrimaryTableSvg,
  EditGreenTableSvg,
  SmallUploadSvg,
  TabPrimaryActionMenu,
} from "assets/images/common/svgs";
import AddEntryTest from "./components/add-refrence";
import AddQualification from "./components/add-career-qualification";
import useEportal from "../../useHooks";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import {
  confirmationPopup,
  warningToaster,
} from "utils/helpers/common/alert-service";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { EditStudentProfile } from "utils/helpers/models/e-portal/edit-student-profile.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import AddEmployment from "./components/add-employement";
import AddPublication from "./components/add-publications";
import AddRefrence from "./components/add-refrence";

interface EditEPortalProfileProps {}

const EditEPortalCareer: FC<EditEPortalProfileProps> = ({}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const academicTableRef = useRef<any>(null);
  const [formData, setFormData] = useState<EditStudentProfile>(
    new EditStudentProfile()
  );

  const [openAcademicModal, setOpenAcademicModal] = useState<boolean>(false);
  const [openReferenceModal, setOpenReferenceModal] = useState<boolean>(false);
  const [openEmploymentModal, setOpenEmploymentModal] =
    useState<boolean>(false);
  const [openPublicationModal, setOpenPublicationModal] =
    useState<boolean>(false);

  const [entryTests, setEntryTests] = useState<any[]>([]);
  const [qualifications, setQualifications] = useState<any[]>([]);
  const [invalidStep, setInvalidStep] = useState<number | undefined>();
  const [oldFormData, setOldFormData] = useState<EditStudentProfile>(
    new EditStudentProfile()
  );
  const navigate = useNavigate();
  const {
    getProfile,
    getCitiesByUser,
    updateUserPersonalInfo,
    updateUserGuradianInfo,
    updateUserAdditionalInfo,
    getEntryTestsByUser,
    getQualificationsByUser,
    deleteQualificationByUser,
    downloadQualificationDocumentByUser,
    deleteEntryTestByUser,
  } = useEportal();
  const {
    setValue,
    getValues,
    register,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm<EditStudentProfile>();
  const [cities, setCities] = useState<any[]>([]);
  const academicColumns: string[] = [
    "Certificate/Degree",
    "Roll No./Reg No.",
    "Board University",
    "Passing Year",
    "Total Marks/GPA",
    "Obtained Marks",
    "Result",
    "Action",
  ];
  const publication: string[] = [
    "Research Article",
    "Publisher Name",
    "Author Name",
    "HEC Category ",
    "Pages",
    "Year of Publication",
    "Month of Publication",
    "Action",
  ];
  const employement: string[] = [
    "Organization",
    "Designation/ Appointment",
    "Salary Drawn",
    "From ",
    "To",
    "Duration ",
    "Reason For Leaving",
    "Action",
  ];
  const refrence: string[] = [
    "Reference Name",
    "Designation",
    "Organization",
    "Relationship",
    "Contact",
    "Email",
    "Action",
  ];
  const editProfileSteps = [
    {
      title: "Personal Information",
      icon: PersonalInfoSvg,
      active: true,
      completed: false,
    },
    {
      title: "Academic Information",
      icon: GuardiansSvg,
      active: false,
      completed: false,
    },
    {
      title: "Employment Information",
      icon: AdditionalInfoSvg,
      active: false,
      completed: false,
    },
    {
      title: "Reference",
      icon: ExamEntrySvg,
      active: false,
      completed: false,
    },
  ];

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const goNext = async (step: number, e?: any) => {
    setCurrentStep(step + 1);
  };

  const goBack = (e: any) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  const goToEditQualification = (qualification: any) => {
    const index = qualifications.findIndex((q) => q.id === qualification.id);
    navigate(`${siteRoutes.ePortalEditProfile}`, { state: { qualification } });
    setOpenAcademicModal(true);
    toggleAcademicDropdown(index);
  };

  const handleDeleteQualification = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      deleteQualificationByUser(id, setQualifications);
    }
  };

  const handleDeleteEntryTest = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      deleteEntryTestByUser(id, setEntryTests);
    }
  };

  const toggleAcademicDropdown = (index: number) => {
    setQualifications([
      ...qualifications.map((item: any, ind: number) => {
        if (index === ind) {
          return {
            ...item,
            isDropdownOpen: !item.isDropdownOpen,
          };
        } else {
          return {
            ...item,
            isDropdownOpen: false,
          };
        }
      }),
    ]);
  };

  const toggleEntryTestsDropdown = (index: number) => {
    setEntryTests([
      ...entryTests.map((item: any, ind: number) => {
        if (index === ind) {
          return {
            ...item,
            isDropdownOpen: !item.isDropdownOpen,
          };
        } else {
          return {
            ...item,
            isDropdownOpen: false,
          };
        }
      }),
    ]);
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    // }
    await trigger([name]);
  };

  const handleFileUpload = (event: any) => {
    const { name, files } = event.target;
    const file = files[0];
    setFormData({ ...formData, [name]: URL.createObjectURL(file) });
    setValue(name, file);
  };

  const onSubmit = (data: any, step: number) => {
    if (step === 1) {
      const fields: any = [
        "gender",
        "date_of_birth",
        "email",
        "phone_no",
        "domicile",
        "p_city",
        "m_city",
        "m_address",
        "p_address",
        "is_self_dependent",
        "profile_image",
        "cnic_image",
        "cnic_back_image",
        "domicile_certificate",
      ];

      if (!formData.profile_image) {
        warningToaster("Profile image is required");
      } else if (!formData.cnic_back_image) {
        warningToaster("Cnic back image is required");
      } else if (!formData.cnic_image) {
        warningToaster("Cnic front image is required");
      } else if (!formData.domicile_certificate) {
        warningToaster("Domicile certificate image is required");
      } else {
        const doesChange = didFormDataChange(fields);
        if (doesChange) {
          const form_data = new FormData();
          for (let key in data) {
            if (fields.includes(key)) {
              if (key === "is_self_dependent") {
                form_data.append(key, formData.is_self_dependent);
              } else {
                form_data.append(key, data[key]);
              }
            }
          }
          updateUserPersonalInfo(form_data, goNext, step);
          setOldFormData({ ...formData });
        } else {
          goNext(step);
        }
      }
    } else if (step === 2) {
      const fields = [
        "guardian_name",
        "guardian_email",
        "guardian_status",
        "guardian_phone",
        "guardian_dependent",
        "guardian_relation",
        "guardian_monthly_income",
        "guardian_cnic",
        "guardian_occupation",
      ];

      const doesChange = didFormDataChange(fields);

      if (doesChange) {
        const form_data = new FormData();
        for (let key in data) {
          if (fields.includes(key)) {
            form_data.append(key, data[key]);
          }
        }
        updateUserGuradianInfo(form_data, goNext, step);
        setOldFormData({ ...formData });
      } else {
        goNext(step);
      }
    } else {
      const fields = [
        "blood_group",
        "how_did_know",
        "disability",
        "religion",
        "is_hafiz",
        "it_deficiency",
        "hostel_check",
        "kin_name",
        "relation_with_kin",
        "kin_cnic",
        "kin_phone",
        "kin_email",
      ];

      const doesChange = didFormDataChange(fields);

      if (doesChange) {
        const form_data = new FormData();
        for (let key in data) {
          if (fields.includes(key)) {
            form_data.append(key, data[key]);
          }
        }

        updateUserAdditionalInfo(form_data, goNext, step);
        setOldFormData({ ...formData });
      } else {
        goNext(step);
      }
    }
  };

  const handleSaveAsAboveCheckbox = async (event: any) => {
    const { checked } = event.target;
    if (checked) {
      const m_city = getValues("m_city");
      const m_address = getValues("m_address");
      setValue("p_city", m_city);
      setValue("p_address", m_address);
      setFormData({ ...formData, p_address: m_address, p_city: m_city });
    } else {
      setValue("p_city", "");
      setValue("p_address", "");
      setFormData({ ...formData, p_address: "", p_city: "" });
    }
    await trigger(["p_city", "p_address"]);
  };

  const handleIsDependentCheckbox = (event: any) => {
    const { checked } = event.target;
    const value = checked ? 1 : 0;
    setValue("is_self_dependent", value);
    setFormData({ ...formData, is_self_dependent: value });
  };

  useEffect(() => {
    getCitiesByUser(setCities);
    getProfile(setFormData, setOldFormData, setValue, formData);
  }, []);

  useEffect(() => {
    getQualificationsByUser(setQualifications);
  }, [openAcademicModal]);

  const didFormDataChange = (fields: string[]) => {
    let doesChange: boolean = false;
    const oldData: any = { ...oldFormData };
    const newData: any = { ...formData };

    for (let key in newData) {
      if (fields.includes(key)) {
        if (oldData[key] !== newData[key]) {
          doesChange = true;
          break;
        }
      }
    }

    return doesChange;
  };
  const goToEportalHome = () => {
    navigate(siteRoutes.ePortalDashboard);
  };
  return (
    <EditEPortalProfileMain>
      <EditEPortalProfileTopSection>
        <span className="page-heading">Edit Profile</span>
        <Breadcrumb />
      </EditEPortalProfileTopSection>
      <EditProfileContentSection>
        <FormStepper>
          <Stepper
            steps={editProfileSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            invalidStep={invalidStep}
          />
        </FormStepper>
        <FormSection>
          <form>
            {currentStep === 1 ? (
              <Step1>
                <div className="common-fields">
                  <div className="input-field">
                    <label>Full Name</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Father Name</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>CNIC/B.Form</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Gender</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select name="" id="">
                          <option value="">Male</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Email</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="email" />
                      </div>
                    </div>
                  </div>

                  <div className="input-field">
                    <label>Mobile Number</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="tel" name="" id="" />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Date Of Birth</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="date" />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Domicile City</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select name="" id="">
                          <option value="">RYK</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="input-field">
                    <label>Current Mailing Address( Address 1, 2)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Enter Mailing Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Current Address (City/Tehsil)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select>
                          <option value="">Select City/Tehsil</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkbox-field">
                  <input type="checkbox" id="same-as-above" />
                  <label htmlFor="same-as-above">Same As Above</label>
                </div>
                <div className="common-fields">
                  <div className="input-field">
                    <label>Permanent Mailing Address( Address 1, 2)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Enter Mailing Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Permanent Address (City/Tehsil)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select>
                          <option value="">Select City/Tehsil</option>
                        </select>
                      </div>
                      <FormErrorMessage error={errors.p_city} />
                    </div>
                  </div>
                </div>
                <div className="checkbox-field">
                  <input type="checkbox" id="is_self_dependent" />
                  <label htmlFor="is_self_dependent">I am self dependent</label>
                </div>

                <div className="upload-field">
                  <label>Profile Picture</label>
                  <label className="field-wrapper">
                    <div className="file-name-section">
                      <div className="inner-content">
                        <div className="upload-text">
                          <div className="upload-icon">
                            <SmallUploadSvg className="icon" />
                          </div>
                          <span className="text">Upload Profile Picture</span>
                        </div>
                        <div className="upload-restrictions">
                          Select a 300x300 jpg image with maximum size of 400 KB
                        </div>
                      </div>
                    </div>
                    <div className="uploaded-image">
                      <img src={dummyAvatar} alt="" />
                    </div>
                    <input type="file" className="d-none" />
                  </label>
                </div>

                <div className="upload-cnic-card">
                  <div className="upload-field">
                    <label htmlFor="cnic_image">CNIC Front Side</label>
                    <label htmlFor="cnic_image" className="field-wrapper">
                      <div className="file-name-section">
                        <div className="inner-content">
                          <div className="upload-text">
                            <div className="upload-icon">
                              <SmallUploadSvg className="icon" />
                            </div>
                            <span className="text">
                              Upload National Identity Card Front Side / B Form
                              ()
                            </span>
                          </div>
                          <div className="upload-restrictions">
                            Select a 300x300 jpg image with maximum size of 400
                            KB
                          </div>
                        </div>
                      </div>
                      <div className="uploaded-image cnic">
                        <img src={squareAvatar} alt="" />
                      </div>
                      <input type="file" id="cnic_image" className="d-none" />
                    </label>
                  </div>
                  <div className="upload-field">
                    <label htmlFor="cnic_front_image">CNIC Back Side</label>
                    <label className="field-wrapper" htmlFor="cnic_front_image">
                      <div className="file-name-section">
                        <div className="inner-content">
                          <div className="upload-text">
                            <div className="upload-icon">
                              <SmallUploadSvg className="icon" />
                            </div>
                            <span className="text">
                              Upload National Identity Card Back Side / B Form
                              ()
                            </span>
                          </div>
                          <div className="upload-restrictions">
                            Select a 300x300 jpg image with maximum size of 400
                            KB
                          </div>
                        </div>
                      </div>
                      <div className="uploaded-image cnic">
                        <img src={squareAvatar} alt="" />
                      </div>
                      <input
                        type="file"
                        id="cnic_front_image"
                        className="d-none"
                      />
                    </label>
                    <FormErrorMessage error={errors.cnic_back_image} />
                  </div>
                </div>
                <div className="upload-field">
                  <label htmlFor="domicile_certificate">
                    Domicile Certificate
                  </label>
                  <label
                    className="field-wrapper"
                    htmlFor="domicile_certificate"
                  >
                    <div className="file-name-section">
                      <div className="inner-content">
                        <div className="upload-text">
                          <div className="upload-icon">
                            <SmallUploadSvg className="icon" />
                          </div>
                          <span className="text">
                            Upload Domicile Certificate
                          </span>
                        </div>
                        <div className="upload-restrictions">
                          Select a 300x300 jpg image with maximum size of 400 KB
                        </div>
                      </div>
                    </div>
                    <div className="uploaded-image domicile">
                      <img src={squareAvatar} alt="" />
                    </div>
                    <input
                      type="file"
                      id="domicile_certificate"
                      className="d-none"
                    />
                  </label>
                </div>
                <div className="submit-buttons">
                  <div className="buttons">
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 1))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step1>
            ) : currentStep === 2 ? (
              <Step2>
                <TableWrapper isTableOverflowing={false}>
                  <div className="header">
                    <div className="heading">
                      <span>Academic Qualification(s)</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenAcademicModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div className="data-table" style={{ overflowX: "unset" }}>
                    <table
                      className="bottom-bordered-cells"
                      ref={academicTableRef}
                    >
                      <thead>
                        <tr>
                          {academicColumns.map(
                            (item: string, index: number) => {
                              return <th key={index}>{item}</th>;
                            }
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {qualifications.map(
                          (qualification: any, index: number) => {
                            return (
                              <tr>
                                <td>Computer Science</td>
                                <td>00000000000000</td>
                                <td>Federal Board</td>
                                <td>2019</td>
                                <td>1100</td>
                                <td>959</td>
                                <td>Final Result</td>
                                <td>
                                  <div className="action-menu">
                                    <div
                                      className="menu-icon cp"
                                      onClick={() =>
                                        toggleAcademicDropdown(index)
                                      }
                                    >
                                      <TabPrimaryActionMenu className="icon" />
                                    </div>
                                    {qualification.isDropdownOpen && (
                                      <div className="menu-dropdown">
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <EditGreenTableSvg className="icon" />
                                          </div>
                                          <span className="title">Edit</span>
                                        </div>
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <DeleteTableSvg className="icon" />
                                          </div>
                                          <span className="title">Delete</span>
                                        </div>
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <DownloadPrimaryTableSvg className="icon" />
                                          </div>
                                          <span className="title">
                                            Download Document
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !qualifications.length} />
                  </Fragment>
                </TableWrapper>

                <div className="submit-buttons">
                  <div className="buttons">
                    <button className="lg-rounded-btn gray" onClick={goBack}>
                      Back
                    </button>
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 2))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step2>
            ) : currentStep === 3 ? (
              <Step3>
                <TableWrapper isTableOverflowing={false}>
                  <div className="header">
                    <div className="heading">
                      <span>Employment Informaiton</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenEmploymentModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div className="data-table" style={{ overflowX: "unset" }}>
                    <table
                      className="bottom-bordered-cells"
                      ref={academicTableRef}
                    >
                      <thead>
                        <tr>
                          {employement.map((item: string, index: number) => {
                            return <th key={index}>{item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {qualifications.map(
                          (qualification: any, index: number) => {
                            return (
                              <tr>
                                <td>IUB</td>
                                <td>Web Master</td>
                                <td>15000</td>
                                <td>2019-07-09</td>
                                <td>2019-07-09</td>
                                <td>1.4 Year</td>
                                <td>Move to another city</td>
                                <td>
                                  <div className="action-menu">
                                    <div
                                      className="menu-icon cp"
                                      onClick={() =>
                                        toggleAcademicDropdown(index)
                                      }
                                    >
                                      <TabPrimaryActionMenu className="icon" />
                                    </div>
                                    {qualification.isDropdownOpen && (
                                      <div className="menu-dropdown">
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <EditGreenTableSvg className="icon" />
                                          </div>
                                          <span className="title">Edit</span>
                                        </div>
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <DeleteTableSvg className="icon" />
                                          </div>
                                          <span className="title">Delete</span>
                                        </div>
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <DownloadPrimaryTableSvg className="icon" />
                                          </div>
                                          <span className="title">
                                            Download Document
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !qualifications.length} />
                  </Fragment>
                </TableWrapper>
                <TableWrapper isTableOverflowing={false}>
                  <div className="header">
                    <div className="heading">
                      <span>Publications</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenPublicationModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div className="data-table" style={{ overflowX: "unset" }}>
                    <table
                      className="bottom-bordered-cells"
                      ref={academicTableRef}
                    >
                      <thead>
                        <tr>
                          {publication.map((item: string, index: number) => {
                            return <th key={index}>{item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {qualifications.map(
                          (qualification: any, index: number) => {
                            return (
                              <tr>
                                <td>NAT</td>
                                <td>M.Iqbal</td>
                                <td>Farooq Ahmad</td>
                                <td>1100</td>
                                <td>1005</td>
                                <td>2019</td>
                                <td>March</td>
                                <td>
                                  <div className="action-menu">
                                    <div
                                      className="menu-icon cp"
                                      onClick={() =>
                                        toggleAcademicDropdown(index)
                                      }
                                    >
                                      <TabPrimaryActionMenu className="icon" />
                                    </div>
                                    {qualification.isDropdownOpen && (
                                      <div className="menu-dropdown">
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <EditGreenTableSvg className="icon" />
                                          </div>
                                          <span className="title">Edit</span>
                                        </div>
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <DeleteTableSvg className="icon" />
                                          </div>
                                          <span className="title">Delete</span>
                                        </div>
                                        <div className="particular-menu cp">
                                          <div className="action-icon">
                                            <DownloadPrimaryTableSvg className="icon" />
                                          </div>
                                          <span className="title">
                                            Download Document
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !qualifications.length} />
                  </Fragment>
                </TableWrapper>
                <div className="submit-buttons">
                  <div className="buttons">
                    <button className="lg-rounded-btn gray" onClick={goBack}>
                      Back
                    </button>
                    <button
                      type="button"
                      className="lg-rounded-btn"
                      onClick={handleSubmit((data: any) => onSubmit(data, 3))}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Step3>
            ) : (
              <Step4>
                <TableWrapper
                  isTableOverflowing={
                    academicTableRef?.current?.scrollHeight >
                    academicTableRef?.current?.offsetHeight
                  }
                >
                  <div className="header">
                    <div className="heading">
                      <span>References</span>
                    </div>
                    <div
                      className="add-btn"
                      onClick={() => setOpenReferenceModal(true)}
                    >
                      <button className="lg-rounded-btn" type="button">
                        Add New
                      </button>
                    </div>
                  </div>
                  <div className="data-table" style={{ overflowX: "unset" }}>
                    <table className="bottom-bordered-cells">
                      <thead>
                        <tr>
                          {refrence.map((item: string, index: number) => {
                            return <th key={index}>{item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {entryTests.map((item: any, index: number) => {
                          return (
                            <tr>
                              <td>Farooq Ahmad</td>
                              <td>Designer</td>
                              <td>IUB</td>
                              <td>Brother</td>
                              <td>+9265454654764</td>
                              <td>iub@456Gmail.com</td>
                              <td>
                                <div className="action-menu">
                                  <div
                                    className="menu-icon"
                                    onClick={() =>
                                      toggleEntryTestsDropdown(index)
                                    }
                                  >
                                    <TabPrimaryActionMenu className="icon" />
                                  </div>
                                  {item.isDropdownOpen && (
                                    <div className="menu-dropdown">
                                      <div className="particular-menu cp">
                                        <div className="action-icon">
                                          <EditGreenTableSvg className="icon" />
                                        </div>
                                        <span className="title">Edit</span>
                                      </div>
                                      <div
                                        className="particular-menu cp"
                                        onClick={() =>
                                          handleDeleteEntryTest(item?.id)
                                        }
                                      >
                                        <div className="action-icon">
                                          <DeleteTableSvg className="icon" />
                                        </div>
                                        <span className="title">Delete</span>
                                      </div>
                                      <div className="particular-menu cp">
                                        <div className="action-icon">
                                          <DownloadPrimaryTableSvg className="icon" />
                                        </div>
                                        <span className="title">
                                          Download Document
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Fragment>
                    <DataNotFound show={!isLoading && !entryTests.length} />
                  </Fragment>
                </TableWrapper>

                <div className="submit-buttons">
                  <div className="back-button">
                    <button onClick={goBack} className="lg-rounded-btn gray">
                      Back
                    </button>
                    <button
                      onClick={goToEportalHome}
                      className="lg-rounded-btn"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Step4>
            )}
          </form>
        </FormSection>
      </EditProfileContentSection>
      {openReferenceModal && <AddRefrence setOpen={setOpenReferenceModal} />}
      {openAcademicModal && <AddQualification setOpen={setOpenAcademicModal} />}
      {openEmploymentModal && (
        <AddEmployment setOpen={setOpenEmploymentModal} />
      )}
      {openPublicationModal && (
        <AddPublication setOpen={setOpenPublicationModal} />
      )}
    </EditEPortalProfileMain>
  );
};

export default EditEPortalCareer;
