// import { FC, useState, useEffect } from "react";
// import {
//   SuperAdminManagementCreateSection,
//   SuperAdminManagementCreateMain,
//   SuperAdminManagementCreateTop,
//   DropdownMain,
// } from "./style";
// import { useForm } from "react-hook-form";
// import FormErrorMessage from "components/particles/forms/form-error-message";
// import Breadcrumb from "components/particles/breadcrumb";
// import useAdmissions from "../../useHooks";
// import { AddMeritFormulaDTO } from "utils/helpers/models/admissions/add-merit-formula.dto";
// import { warningToaster } from "utils/helpers/common/alert-service";
// import useUtils from "hooks/useUtils";
// import { DynamicField } from "utils/helpers/models/admissions/add-merit-formula.dto";

// const CreateMeritListFormula: FC = () => {
//   const [formData, setFormData] = useState<AddMeritFormulaDTO>(
//     new AddMeritFormulaDTO()
//   );

//   const [meritKeys, setMeritKeys] = useState<any[]>([]);
//   const {
//     getMeritKeys,
//     createMeritFormula,
//     updateMeritFormula,
//     getMeritFormulaById,
//   } = useAdmissions();

//   const {
//     handleSubmit,
//     register,
//     formState: { errors },
//     setValue,
//     trigger,
//   } = useForm<AddMeritFormulaDTO>();
//   const { getQueryParams } = useUtils();
//   const params = getQueryParams();

//   const onSubmit = (data: any, addMore: boolean = false) => {
//     const optionTypes = ["dropdown", "checkbox", "radio"];
//     const extra_fields: any = { ...formData }.extra_fields.map((item: any) => {
//       if (!optionTypes.includes(item.type)) {
//         delete item.options;
//       }
//       return item;
//     });

//     if (params?.id) {
//       updateMeritFormula(params?.id, { ...formData, extra_fields });
//     } else {
//       createMeritFormula({ ...formData, extra_fields }, addMore, resetForm);
//     }
//   };

//   const handleSelect = (meritKey: any, index: number) => {
//     const { key_title: title, id } = meritKey;
//     const doesAlreadyExist = formData.merit_keys.find((key) => key.id === id);
//     if (doesAlreadyExist) {
//       formData.merit_keys.splice(index, 1);
//     } else {
//       formData.merit_keys = [...formData.merit_keys, { title, id }];
//     }

//     setFormData({ ...formData });
//   };

//   // const handleChange = (event: any) => {
//   //   const { value, name } = event.target;
//   //   setValue(name, value);
//   //   trigger([name]);
//   //   setFormData({ ...formData, [name]: value });
//   // };

//   const handleFormulaChange = (value: string, index: number) => {
//     formData.merit_keys[index].title = value;
//     setFormData({ ...formData });
//   };

//   const extractAfterZeroDot = (str: string, fieldIndex: number = 0) => {
//     const prefix = `${fieldIndex}.`;
//     const index = str.indexOf(prefix);

//     if (index !== -1) {
//       return str.substring(index + prefix.length);
//     }

//     return "";
//   };

//   const resetForm = () => {
//     for (let key in formData) {
//       if (key === "merit_keys") {
//         setValue(key as keyof AddMeritFormulaDTO, []);
//       } else {
//         setValue(key as keyof AddMeritFormulaDTO, "");
//       }
//     }

//     setFormData({ ...new AddMeritFormulaDTO() });
//   };
//   const handleChange = (event: any) => {
//     const { value, name } = event.target;
//     const form_data: any = { ...formData };
//     form_data[name] = value;
//     setValue(name as keyof AddMeritFormulaDTO, value);
//     if (name === "additional_info" && value == 1) {
//     } else if (name === "additional_info") {
//       form_data.extra_fields = [];
//     }

//     trigger([name]);

//     setFormData({ ...form_data });
//   };
//   // const handleDynamicFieldChange = (event: any, index: number) => {
//   //   const { value, name } = event.target;
//   //   setValue(name, value);
//   //   const form_data: any = { ...formData };
//   //   const formDataName = extractAfterZeroDot(name, index);
//   //   form_data.extra_fields[index][formDataName] = value;
//   //   trigger([name]);
//   //   setFormData({ ...form_data });
//   // };

//   const handleDynamicFieldChange = (event: any, index: number) => {
//     const { value, name } = event.target;
//     const updatedFields = [...formData.extra_fields];
//     const fieldName = extractAfterZeroDot(name);

//     updatedFields[index] = {
//       ...updatedFields[index],
//       [fieldName]: value,
//     };

//     setFormData({ ...formData, extra_fields: updatedFields });
//   };
//   const handleDeleteOption = (fieldIndex: number, optionIndex: number) => {
//     // Simply remove the option at the index without involving 'options'
//     formData.extra_fields.splice(optionIndex, 1);
//     setFormData({ ...formData });
//   };

//   const handleAddOption = (fieldIndex: number) => {
//     // Add a new item to 'extra_fields' without 'options'
//     formData.extra_fields.push();
//     setFormData({ ...formData });
//   };
//   console.log(DynamicField);

//   const [visibleFields, setVisibleFields] = useState<Record<number, boolean>>(
//     {}
//   );
//   const handleToggleVisibility = (index: number) => {
//     setVisibleFields((prev) => ({
//       ...prev,
//       [index]: !prev[index],
//     }));
//   };
//   return (
//     <SuperAdminManagementCreateMain>
//       <SuperAdminManagementCreateTop>
//         <div className="left">
//           <span className="page-heading">Add Merit Formula</span>
//           <Breadcrumb />
//         </div>
//         <div className="right"></div>
//       </SuperAdminManagementCreateTop>

//       <SuperAdminManagementCreateSection className="p-custom-scrollbar-8">
//         <form>
//           <div className="common-fields">
//             <div className="input-field">
//               <label>Name</label>
//               <div className="field-wrap">
//                 <div className="field">
//                   <input
//                     type="text"
//                     placeholder="Name"
//                     value={formData.title}
//                     {...register("title", { required: true })}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <FormErrorMessage error={errors.title} />
//               </div>
//             </div>
//             <div className="radio-field">
//               <label htmlFor="no">Status</label>
//               <div className="field-wrap">
//                 <div className="field">
//                   <input
//                     type="radio"
//                     id="no"
//                     checked={formData.is_active == 0}
//                     {...register("is_active", { required: true })}
//                     onChange={handleChange}
//                     value={0}
//                   />
//                   <label htmlFor="no">De-active</label>
//                 </div>
//                 <div className="field">
//                   <input
//                     type="radio"
//                     id="yes"
//                     checked={formData.is_active == 1}
//                     {...register("is_active", { required: true })}
//                     onChange={handleChange}
//                     value={1}
//                   />
//                   <label htmlFor="yes">Active</label>
//                 </div>
//               </div>
//               <FormErrorMessage error={errors.is_active} />
//             </div>
//           </div>
//           {formData.extra_fields.map((field: DynamicField, index: number) => (
//             <div className="form-data" key={index}>
//               <div className="options-fields">
//                 {field.options.map((option: string, ind: number) => (
//                   <div key={ind}>
//                     <div className="data-fields">
//                       <div className="input-field">
//                         <label>Certificate Levels</label>
//                         <div className="field-wrap">
//                           <div className="field">
//                             <select
//                               name={`certificateLevel_${index}_${ind}`}
//                               id={`certificateLevel_${index}_${ind}`}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             >
//                               <option value="">Select</option>
//                               {/* Add dynamic options here if needed */}
//                             </select>
//                           </div>
//                           {/* <FormErrorMessage error={errors[`certificateLevel_${index}_${ind}`]} /> */}
//                         </div>
//                       </div>

//                       <div className="input-field">
//                         <label>Result Types</label>
//                         <div className="field-wrap">
//                           <div className="field">
//                             <select
//                               name={`resultType_${index}_${ind}`}
//                               id={`resultType_${index}_${ind}`}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             >
//                               <option value="">Select</option>
//                               {/* Add dynamic options here if needed */}
//                             </select>
//                           </div>
//                           {/* <FormErrorMessage error={errors[`resultType_${index}_${ind}`]} /> */}
//                         </div>
//                       </div>

//                       <div className="radio-field">
//                         <label htmlFor={`isHafiz_${index}`}>Is Hafiz</label>
//                         <div className="field-wrap">
//                           <div className="field">
//                             <input
//                               type="radio"
//                               id={`isHafizNo_${index}`}
//                               name={`isHafiz_${index}`}
//                               value="no"
//                               checked={formData.is_active === 0}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             />
//                             <label htmlFor={`isHafizNo_${index}`}>No</label>
//                           </div>
//                           <div className="field">
//                             <input
//                               type="radio"
//                               id={`isHafizYes_${index}`}
//                               name={`isHafiz_${index}`}
//                               value="yes"
//                               checked={formData.is_active === 1}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             />
//                             <label htmlFor={`isHafizYes_${index}`}>Yes</label>
//                           </div>
//                         </div>
//                         {/* <FormErrorMessage error={errors[`isHafiz_${index}`]} /> */}
//                       </div>

//                       <div className="radio-field">
//                         <label htmlFor={`isTest_${index}`}>Is Test</label>
//                         <div className="field-wrap">
//                           <div className="field">
//                             <input
//                               type="radio"
//                               id={`isTestNo_${index}`}
//                               name={`isTest_${index}`}
//                               value="no"
//                               checked={formData.is_active === 0}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             />
//                             <label htmlFor={`isTestNo_${index}`}>No</label>
//                           </div>
//                           <div className="field">
//                             <input
//                               type="radio"
//                               id={`isTestYes_${index}`}
//                               name={`isTest_${index}`}
//                               value="yes"
//                               checked={formData.is_active === 1}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             />
//                             <label htmlFor={`isTestYes_${index}`}>Yes</label>
//                           </div>
//                         </div>
//                         {/* <FormErrorMessage error={errors[`isTest_${index}`]} /> */}
//                       </div>

//                       <div className="input-field">
//                         <label>Certificate Level Weightage</label>
//                         <div className="field-wrap">
//                           <div className="field">
//                             <input
//                               type="number"
//                               name={`certificateLevelWeightage_${index}_${ind}`}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             />
//                           </div>
//                           {/* <FormErrorMessage error={errors[`certificateLevelWeightage_${index}_${ind}`]} /> */}
//                         </div>
//                       </div>

//                       <div className="input-field">
//                         <label>Test Weightage</label>
//                         <div className="field-wrap">
//                           <div className="field">
//                             <input
//                               type="number"
//                               name={`testWeightage_${index}_${ind}`}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             />
//                           </div>
//                           {/* <FormErrorMessage error={errors[`testWeightage_${index}_${ind}`]} /> */}
//                         </div>
//                       </div>

//                       <div className="input-field">
//                         <label>Hafiz Marks</label>
//                         <div className="field-wrap">
//                           <div className="field">
//                             <input
//                               type="number"
//                               name={`hafizMarks_${index}_${ind}`}
//                               onChange={(e) =>
//                                 handleDynamicFieldChange(e, index)
//                               }
//                             />
//                           </div>
//                           {/* <FormErrorMessage error={errors[`hafizMarks_${index}_${ind}`]} /> */}
//                         </div>
//                       </div>
//                     </div>

//                     <div className="action-buttons">
//                       <div className="buttons">
//                         <button
//                           className="lg-rounded-btn gray"
//                           type="button"
//                           onClick={() => handleDeleteOption(index, ind)}
//                         >
//                           Remove
//                         </button>
//                         <button
//                           className="lg-rounded-btn black"
//                           onClick={() => handleAddOption(index)}
//                         >
//                           Add more
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ))}

//           {/* <div>
//             <label className="key-body-label">Merit Keys</label>
//             <DropdownMain>
//               <ul className="p-custom-scrollbar-8">
//                 {meritKeys.map((item: any, index: number) => (
//                   <li key={index} onClick={() => handleSelect(item, index)}>
//                     <div className="checkbox">
//                       <input
//                         type="checkbox"
//                         checked={
//                           formData.merit_keys.find((k) => k.id === item.id)
//                             ? true
//                             : false
//                         }
//                       />
//                     </div>
//                     <div className="item-text">
//                       <span className="text">{item.key_title}</span>
//                     </div>
//                   </li>
//                 ))}
//               </ul>
//             </DropdownMain>
//           </div>

//         <div>
//             <label htmlFor="" className="key-body-label">
//               Merit
//             </label>
//             <div className="merit-section">
//               {formData.merit_keys.map((meritKey, index) => {
//                 return (
//                   <div className="field">
//                     <input
//                       type="text"
//                       value={meritKey.title}
//                       onChange={(e) =>
//                         handleFormulaChange(e.target.value, index)
//                       }
//                     />
//                   </div>
//                 );
//               })}
//             </div>
//           </div> */}

//           <div className="action-buttons">
//             <div className="buttons">
//               <button
//                 className="lg-rounded-btn gray"
//                 type="button"
//                 onClick={resetForm}
//               >
//                 Reset
//               </button>
//               <button
//                 className="lg-rounded-btn black"
//                 onClick={() =>
//                   handleSubmit((data: AddMeritFormulaDTO) =>
//                     onSubmit(data, true)
//                   )
//                 }
//               >
//                 Save & Add more
//               </button>
//               <button
//                 className="lg-rounded-btn"
//                 onClick={handleSubmit((data: AddMeritFormulaDTO) =>
//                   onSubmit(data)
//                 )}
//               >
//                 Save & Exit
//               </button>
//             </div>
//           </div>
//         </form>
//       </SuperAdminManagementCreateSection>
//     </SuperAdminManagementCreateMain>
//   );
// };

// export default CreateMeritListFormula;

import { FC, useState, useEffect } from "react";
import {
  SuperAdminManagementCreateSection,
  SuperAdminManagementCreateMain,
  SuperAdminManagementCreateTop,
} from "./style";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import Breadcrumb from "components/particles/breadcrumb";
import useAdmissions from "../../useHooks";
import {
  AddMeritFormulaDTO,
  DynamicField,
} from "utils/helpers/models/admissions/add-merit-formula.dto";
import { warningToaster } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";

const CreateMeritListFormula: FC = () => {
  const [formData, setFormData] = useState<AddMeritFormulaDTO>(
    new AddMeritFormulaDTO()
  );
  const [meritKeys, setMeritKeys] = useState<any[]>([]); // Define meritKeys state

  const {
    getMeritKeys,
    createMeritFormula,
    updateMeritFormula,
    getMeritFormulaById,
  } = useAdmissions();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<AddMeritFormulaDTO>();

  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const onSubmit = (data: any, addMore: boolean = false) => {
    if (params?.id) {
      updateMeritFormula(params.id, { ...formData });
    } else {
      createMeritFormula({ ...formData }, addMore, resetForm);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name as keyof AddMeritFormulaDTO, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const handleDynamicFieldChange = (event: any, index: number) => {
    const { value, name } = event.target;
    const updatedFields = [...formData.extra_fields];
    const fieldName = extractAfterZeroDot(name);

    updatedFields[index] = {
      ...updatedFields[index],
      [fieldName]: value,
    };

    setFormData({ ...formData, extra_fields: updatedFields });
  };

  const handleAddDynamicField = () => {
    const newField = new DynamicField();
    setFormData({
      ...formData,
      extra_fields: [...formData.extra_fields, newField],
    });
  };

  const resetForm = () => {
    for (let key in formData) {
      if (key === "merit_keys") {
        setValue(key as keyof AddMeritFormulaDTO, []);
      } else {
        setValue(key as keyof AddMeritFormulaDTO, "");
      }
    }

    setFormData(new AddMeritFormulaDTO());
  };

  const extractAfterZeroDot = (str: string, fieldIndex: number = 0) => {
    const prefix = `${fieldIndex}.`;
    const index = str.indexOf(prefix);
    if (index !== -1) {
      return str.substring(index + prefix.length);
    }
    return "";
  };

  return (
    <SuperAdminManagementCreateMain>
      <SuperAdminManagementCreateTop>
        <div className="left">
          <span className="page-heading">Add Merit Formula</span>
          <Breadcrumb />
        </div>
        <div className="right"></div>
      </SuperAdminManagementCreateTop>

      <SuperAdminManagementCreateSection className="p-custom-scrollbar-8">
        <form>
          <div className="common-fields">
            <div className="input-field">
              <label>Name</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="text"
                    placeholder="Name"
                    value={formData.title}
                    {...register("title", { required: true })}
                    onChange={handleChange}
                  />
                </div>
                <FormErrorMessage error={errors.title} />
              </div>
            </div>
            <div className="radio-field">
              <label htmlFor="no">Status</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="radio"
                    id="no"
                    checked={formData.is_active === 0}
                    {...register("is_active", { required: true })}
                    onChange={handleChange}
                    value={0}
                  />
                  <label htmlFor="no">De-active</label>
                </div>
                <div className="field">
                  <input
                    type="radio"
                    id="yes"
                    checked={formData.is_active === 1}
                    {...register("is_active", { required: true })}
                    onChange={handleChange}
                    value={1}
                  />
                  <label htmlFor="yes">Active</label>
                </div>
              </div>
              <FormErrorMessage error={errors.is_active} />
            </div>
          </div>

          <div className="form-data">
            <div className="options-fields">
              <div>
                <div className="data-fields">
                  <div className="input-field">
                    <label>Certificate Levels</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select>
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="input-field">
                    <label>Result Types</label>
                    <div className="field-wrap">
                      <div className="field">
                        <select>
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="radio-field">
                    <label>Is Hafiz</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="radio" />
                        <label>No</label>
                      </div>
                      <div className="field">
                        <input type="radio" />
                        <label>Yes</label>
                      </div>
                    </div>
                  </div>

                  <div className="radio-field">
                    <label>Is Test</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="radio" />
                        <label>No</label>
                      </div>
                      <div className="field">
                        <input type="radio" />
                        <label>Yes</label>
                      </div>
                    </div>
                    {/* <FormErrorMessage error={errors[`isTest_${index}`]} /> */}
                  </div>

                  <div className="input-field">
                    <label>Certificate Level Weightage</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="number" />
                      </div>
                      {/* <FormErrorMessage error={errors[`certificateLevelWeightage_${index}_${ind}`]} /> */}
                    </div>
                  </div>

                  <div className="input-field">
                    <label>Test Weightage</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="number" />
                      </div>
                      {/* <FormErrorMessage error={errors[`testWeightage_${index}_${ind}`]} /> */}
                    </div>
                  </div>

                  <div className="input-field">
                    <label>Hafiz Marks</label>
                    <div className="field-wrap">
                      <div className="field">
                        <input type="number" />
                      </div>
                      {/* <FormErrorMessage error={errors[`hafizMarks_${index}_${ind}`]} /> */}
                    </div>
                  </div>
                  <div className="action-buttons">
                    <div className="buttons">
                      <button className="lg-rounded-btn gray" type="button">
                        Remove
                      </button>
                      <button className="lg-rounded-btn black">Add more</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="action-buttons">
            <div className="buttons">
              <button
                className="lg-rounded-btn gray"
                type="button"
                onClick={resetForm}
              >
                Reset
              </button>
              <button
                className="lg-rounded-btn black"
                onClick={() =>
                  handleSubmit((data: AddMeritFormulaDTO) =>
                    onSubmit(data, true)
                  )
                }
              >
                Save & Add more
              </button>
              <button
                className="lg-rounded-btn"
                onClick={handleSubmit((data: AddMeritFormulaDTO) =>
                  onSubmit(data)
                )}
              >
                Save & Exit
              </button>
            </div>
          </div>
        </form>
      </SuperAdminManagementCreateSection>
    </SuperAdminManagementCreateMain>
  );
};

export default CreateMeritListFormula;
