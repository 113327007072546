import { FC, Fragment, useState } from "react";
import {
  AcademicSessionListingSection,
  AcademicSessionListingTop,
  AcademicSessionListingMain,
} from "./style";
import {
  DeleteTableSvg,
  DownloadApplicationSvg,
  DownloadChallanSvg,
  DownloadDocumentSvg,
  DownloadPrimaryTableSvg,
  EditGreenTableSvg,
  PrintGreenSvg,
  TabPrimaryActionMenu,
  UploadChallanSvg,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";

interface AcademicSessionListingProps {}

const AppliedJobsListing: FC<AcademicSessionListingProps> = ({}) => {
  const columns: string[] = [
    "Batch",
    "Job Post",
    "Department",
    "Job Type  ",
    "Campus",
    "Date Applied",
    "Closing Date",
    "Challan Status",
    "Action",
  ];

  const [action, setAction] = useState<any[]>([]);

  const toggleEntryTestsDropdown = (index: number) => {
    setAction([
      ...action.map((item: any, ind: number) => {
        if (index === ind) {
          return {
            ...item,
            isDropdownOpen: !item.isDropdownOpen,
          };
        } else {
          return {
            ...item,
            isDropdownOpen: false,
          };
        }
      }),
    ]);
  };

  return (
    <AcademicSessionListingMain>
      <AcademicSessionListingTop>
        <div className="left">
          <span className="page-heading">Applied Jobs</span>
          <Breadcrumb />
        </div>
        <div className="right"></div>
      </AcademicSessionListingTop>
      <AcademicSessionListingSection className="content-radius-shadow">
        <div>
          <span className="table-heading">General Information </span>
          {/* <div className="info-div">
            <p className="table-info">
              STEP 1. Click on 'Apply' button from available jobs list to apply
              for job and proceed to Step 2.
            </p>
            <p className="table-info">
              STEP 2. Download the Challan from applied Job appeared in the list
              below
            </p>
            <p className="table-info">
              STEP 3. Pay your Challan online through HBL MOBILE APP Branch, HBL
              KONNECT Agent, JAZZCASH Agent , HBL KONNECT APP, JAZZCASH APP OR
              you can pay by visiting any HBL
            </p>
            <p className="table-info">STEP 4. Upload paid Challan picture.</p>
            <p className="table-info">
              STEP 5. Verify data by clicking 'Print Application', after
              verification click 'Submit' to submit online application.
            </p>
            <p className="table-info">
              STEP 6. Submit print of online application form, required
              documents and paid challan by Post before closing date. Alert!
              Challan verification process can take upto 48 hours.
            </p>
          </div> */}
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {action.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>IPB-368</td>
                      <td>
                        <div className="mw-150">Assistant Director IT</div>
                      </td>
                      <td>Registrar’s Office</td>
                      <td>Contract</td>
                      <td>Bahawalpur (Main Campus)</td>
                      <td>10-02-2019</td>
                      <td>10-02-2019</td>
                      <td>Paid</td>
                      <td>
                        <div className="table-action-icons">
                          <div className="action-menu">
                            <div
                              className="menu-icon"
                              onClick={() => toggleEntryTestsDropdown(index)}
                            >
                              <TabPrimaryActionMenu className="icon" />
                            </div>
                            {item.isDropdownOpen && (
                              <div className="menu-dropdown">
                                <div className="particular-menu cp">
                                  <div className="action-icon">
                                    <PrintGreenSvg className="icon" />
                                  </div>
                                  <span className="title">
                                    Print Application
                                  </span>
                                </div>
                                <div className="particular-menu cp">
                                  <div className="action-icon">
                                    <DownloadApplicationSvg className="icon" />
                                  </div>
                                  <span className="title">
                                    Download Application
                                  </span>
                                </div>
                                <div className="particular-menu cp">
                                  <div className="action-icon">
                                    <DownloadDocumentSvg className="icon" />
                                  </div>
                                  <span className="title">
                                    Download Document
                                  </span>
                                </div>
                                <div className="particular-menu cp">
                                  <div className="action-icon">
                                    <DownloadChallanSvg className="icon" />
                                  </div>
                                  <span className="title">
                                    Download Challan
                                  </span>
                                </div>
                                <div className="particular-menu cp">
                                  <div className="action-icon">
                                    <UploadChallanSvg className="icon" />
                                  </div>
                                  <span className="title">Upload Challan</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          {/* <Pagination onPageChange={onPageChange} {...pagination} /> */}
        </Fragment>
      </AcademicSessionListingSection>
    </AcademicSessionListingMain>
  );
};

export default AppliedJobsListing;
