import { FC, useEffect, useState } from "react";
import {
  CreateInstallmentsForm,
  CreateInstallmentsMain,
  CreateInstallmentsTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { VoucherTemplateHeaderDTO } from "utils/helpers/models/finance/voucher-template-header.dto";
import { useForm } from "react-hook-form";
import useFinance from "../../useHooks";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { VoucherTypeDTO } from "utils/helpers/models/finance/voucher-type.dto";
import { BankInfoDTO } from "utils/helpers/models/finance/bank-info.dto";
import MultiselectDropdown from "components/particles/forms/multiselect-dropdown";
import useComponentVisible from "hooks/click-outside";
import { warningToaster } from "utils/helpers/common/alert-service";

interface CreateVoucherTemplateHeaderProps {}

const CreateInstallments: FC<CreateVoucherTemplateHeaderProps> = ({}) => {
  return (
    <CreateInstallmentsMain>
      <CreateInstallmentsTop>
        <div className="heading">
          <span className="page-heading">Add Installment</span>
          <Breadcrumb />
        </div>
      </CreateInstallmentsTop>
      <CreateInstallmentsForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label htmlFor="application">Installment Title</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" placeholder="Title" />
              </div>
            </div>
          </div>
          <div className="radio-field">
            <label htmlFor="no">Show Percentage</label>
            <div className="field-wrap">
              <div className="field">
                <input type="radio" id="no" />
                <label htmlFor="no">No</label>
              </div>
              <div className="field">
                <input type="radio" id="yes" />
                <label htmlFor="yes">Yes </label>
              </div>
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button">
              Cancel
            </button>
            <button className="lg-rounded-btn">Submit</button>
          </div>
        </div>
      </CreateInstallmentsForm>
    </CreateInstallmentsMain>
  );
};

export default CreateInstallments;
