export class JobTemplateDTO {
    title: string = '';
    description: string = '';
    required_experience: string = '';
    pay_scale: string = '';
    age_min: string = '';
    age_max: string = '';
    eligibility: string = '';
    department: string = '';
    challan_fee: string = '';
    voucher_template: string = '';
    designation_id: string = '';
    research: string = '';
    preferences: string = '';
    min_qualification: string = '';
    modified_date: string = '';
    additional_notes: string = '';

}