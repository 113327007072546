export class AddOrgStructureDTO {
    categories_types_id: string = '';
    parent_id: string = '';
    title: string = '';
    // campus: string = '';
    website: string = '';
    email: string = '';
    phone: string = '';
    address: string = '';
    logo: any = '';
    mission: string = '';
    vision: string = '';
    city_id: string = '';
    slug: any;
    district: string = '';
    parent_type_id: string = ''
}