import { FC, useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuItem,
  MenuItem,
  Menus,
  SidebarMain,
  SiteLogo,
} from "./style";
import {
  AdministrationSvg,
  AdmissionSvg,
  BooksSvg,
  BuildingsSvg,
  CareerSvg,
  ChallanSvg,
  CoursesSvg,
  CyfyLogoDark,
  CyfyLogoSvg,
  DropdownIcon,
  GraduationSvg,
  HomeSvg,
  HostelSvg,
  JobsSvg,
  MeritListSvg,
  PrivilegesSvg,
  ProfileSvg,
  SettingsSolidSvg,
  SideShortCoursesSvg,
  TestingSvg,
  VoucherSvg,
} from "assets/images/common/svgs";
import { useLocation, useNavigate } from "react-router-dom";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
import { domains } from "utils/helpers/enums/shared.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { useSelector } from "react-redux";
import { FinanceSvg } from "assets/images/finance/svgs";

interface SidebarProps {
  openSidebar: boolean;
  setOpenSidebar: Function;
}
const Sidebar: FC<SidebarProps> = ({ setOpenSidebar }) => {
  const { hasAccess, getDomainType } = useStore();
  const { isCurrentWidthMinimum, splitPathBySlash } = useUtils();
  const { pathname } = useLocation();
  const { isDarkTheme } = useSelector((state: any) => state.sharedReducer);
  const sidebarMenus = [
    {
      title: "Dashboard",
      path: siteRoutes.superAdminDashboard,
      subMenus: [],
      permission: sitePermissions.superAdminDashboard,
      icon: HomeSvg,
      link: siteRoutes.superAdminDashboard,
      isDropdownOpen: false,
    },
    {
      title: "Dashboard",
      path: siteRoutes.adminDashboard,
      subMenus: [],
      permission: sitePermissions.adminDashboard,
      icon: HomeSvg,
      link: siteRoutes.adminDashboard,
      isDropdownOpen: false,
    },
    {
      title: "Organization",
      path: splitPathBySlash(siteRoutes.organizationListing)[2],
      icon: BuildingsSvg,
      subMenus: [
        {
          title: "Organization",
          path: siteRoutes.organizationListing,
          active: "org",
          permission: sitePermissions.organizationListing,
          subPagesPermissions: [
            sitePermissions.organizationListing,
            sitePermissions.createOrganization,
          ],
        },
        {
          title: "Structure Types",
          path: siteRoutes.structureTypeListing,
          active: "structure-types",
          permission: sitePermissions.structureTypeListing,
          subPagesPermissions: [
            sitePermissions.structureTypeListing,
            sitePermissions.createStructureType,
          ],
          // adminOptional: !organization?.add_structure_type,
          // superAdminOptional: true
        },
        {
          title: "Organizational Structure",
          path: siteRoutes.orgStructureListing,
          active: "org-structure",
          permission: sitePermissions.orgStructureListing,
          subPagesPermissions: [
            sitePermissions.orgStructureListing,
            sitePermissions.createOrgStructure,
          ],
        },
        {
          title: "Asset Management",
          path: siteRoutes.assetsListing,
          active: "assets-management",
          permission: sitePermissions.assetListing,
          subPagesPermissions: [
            sitePermissions.assetListing,
            sitePermissions.createAsset,
          ],
        },
        {
          title: "Building Managament",
          path: siteRoutes.buildingListing,
          active: "buildings-management",
          permission: sitePermissions.buildingListing,
          subPagesPermissions: [
            sitePermissions.buildingListing,
            sitePermissions.createBuilding,
          ],
        },
        {
          title: "Programs",
          path: siteRoutes.programListing,
          active: "programs",
          permission: sitePermissions.programListing,
          subPagesPermissions: [
            sitePermissions.programListing,
            sitePermissions.createProgram,
          ],
        },
        {
          title: "Result Types",
          path: siteRoutes.resultTypeListing,
          active: "result-type",
          permission: sitePermissions.resultTypeListing,
          subPagesPermissions: [sitePermissions.resultTypeListing],
        },
        // {
        //     title: "Certificate Links",
        //     path: siteRoutes.certificateLinkListing,
        //     active: "certificate-link",
        //     permission: sitePermissions.certificateLinkListing,
        //     subPagesPermissions: [
        //         sitePermissions.certificateLinkListing,

        //     ]
        // },
        // {
        //   title: "Role Management",
        //   path: siteRoutes.roleManagementListing,
        //   active: "roll-management",
        //   permission: sitePermissions.roleManagementListing,
        //   subPagesPermissions: [sitePermissions.roleManagementListing],
        // },
        // {
        //     title: "Board Management",
        //     path: siteRoutes.boardManagementListing,
        //     active: "board-management",
        //     permission: sitePermissions.boardManagementListing,
        //     subPagesPermissions: [
        //         sitePermissions.boardManagementListing,

        //     ]
        // },
        // {
        //     title: "Subject Management",
        //     path: siteRoutes.subjectManagementListing,
        //     active: "subject-management",
        //     permission: sitePermissions.subjectManagementListing,
        //     subPagesPermissions: [
        //         sitePermissions.subjectManagementListing,

        //     ]
        // },
        // {
        //     title: "Certificate Management",
        //     path: siteRoutes.certificateManagementListing,
        //     active: "certificate-management",
        //     permission: sitePermissions.certificateManagementListing,
        //     subPagesPermissions: [
        //         sitePermissions.certificateManagementListing,

        //     ]
        // },
        {
          title: "Admins",
          path: siteRoutes.superAdminManagementListing,
          active: "super-admin-management",
          permission: sitePermissions.superAdminManagementListing,
          subPagesPermissions: [sitePermissions.superAdminManagementListing],
        },
        {
          title: "Role Management",
          path: siteRoutes.superRoleManagementListing,
          active: "super-role-management",
          permission: sitePermissions.superRoleManagementListing,
          subPagesPermissions: [sitePermissions.superRoleManagementListing],
        },
        {
          title: "Permission Management",
          path: siteRoutes.permissionManagementListing,
          active: "permission-management",
          permission: sitePermissions.permissionManagementListing,
          subPagesPermissions: [sitePermissions.permissionManagementListing],
        },
        {
          title: "Module Management",
          path: siteRoutes.moduleManagement,
          active: "module-management",
          permission: sitePermissions.moduleManagement,
          subPagesPermissions: [sitePermissions.moduleManagement],
        },
        // {
        //     title: "Linked Program",
        //     path: siteRoutes.linkedProgramEntryTestListing,
        //     active: "linked-program",
        //     permission: sitePermissions.linkedProgramEntryTestListing,
        //     subPagesPermissions: [
        //         sitePermissions.moduleManagement,

        //     ]
        // },
        {
          title: "Academic Session",
          path: siteRoutes.academicSessionOrganizationListing,
          active: "academic-session-organization",
          permission: sitePermissions.academicSessionOrganizationListing,
          subPagesPermissions: [
            sitePermissions.academicSessionOrganizationListing,
          ],
        },
      ],
      isDropdownOpen: false,
    },
    {
      title: "Admissions",
      path: splitPathBySlash(siteRoutes.admissionCampaignListing)[2],
      subMenus: [
        {
          title: "Admission Campaigns",
          path: siteRoutes.admissionCampaignListing,
          active: splitPathBySlash(siteRoutes.admissionCampaignListing)[3],
          permission: sitePermissions.admissionCampaignListing,
          subPagesPermissions: [
            sitePermissions.createAdmissionCampaign,
            sitePermissions.admissionCampaignListing,
          ],
        },
        {
          title: "Admission Session",
          path: siteRoutes.admissionSessionListing,
          active: splitPathBySlash(siteRoutes.admissionSessionListing)[3],
          permission: sitePermissions.admissionSessionListing,
          subPagesPermissions: [
            sitePermissions.createAdmissionSession,
            sitePermissions.admissionSessionListing,
          ],
        },
        {
          title: "Students Applications",
          path: siteRoutes.admissionStudentListing,
          active: splitPathBySlash(siteRoutes.admissionStudentListing)[3],
          permission: sitePermissions.admissionStudentListing,
          subPagesPermissions: [sitePermissions.admissionStudentListing],
        },
        {
          title: "Student applications view only",
          path: siteRoutes.admissionApplicantsListing,
          active: splitPathBySlash(siteRoutes.admissionApplicantsListing)[3],
          permission: sitePermissions.admissionApplicantsListing,
          subPagesPermissions: [sitePermissions.admissionApplicantsListing],
        },

        {
          title: "Manage Document",
          path: siteRoutes.admissionDocumentListing,
          active: splitPathBySlash(siteRoutes.admissionDocumentListing)[3],
          permission: sitePermissions.admissionDocumentListing,
          subPagesPermissions: [
            sitePermissions.admissionDocumentListing,
            sitePermissions.editAdmissionDocument,
          ],
        },
        {
          title: "Test Types",
          path: siteRoutes.admissionTestTypesListing,
          active: splitPathBySlash(siteRoutes.admissionTestTypesListing)[3],
          permission: sitePermissions.admissionTestTypesListing,
          subPagesPermissions: [
            sitePermissions.admissionTestTypesListing,
            sitePermissions.createAdmissionTestType,
          ],
        },
        {
          title: "Registered Students",
          path: siteRoutes.studentRegListing,
          active: splitPathBySlash(siteRoutes.studentRegListing)[3],
          permission: sitePermissions.studentRegListing,
          subPagesPermissions: [sitePermissions.studentRegListing],
        },
        {
          title: "Merit List",
          path: siteRoutes.admissionMeritListing,
          active: splitPathBySlash(siteRoutes.admissionMeritListing)[3],
          permission: sitePermissions.admissionMeritListing,
          subPagesPermissions: [sitePermissions.admissionMeritListing],
        },
        {
          title: "Offer Letter Templates",
          path: siteRoutes.admissionOfferLetterListing,
          active: splitPathBySlash(siteRoutes.admissionOfferLetterListing)[3],
          permission: sitePermissions.admissionOfferLetterListing,
          subPagesPermissions: [
            sitePermissions.admissionOfferLetterListing,
            sitePermissions.createAdmissionOfferLetter,
          ],
        },
        // {
        //     title: "Board Management",
        //     path: siteRoutes.editAdmissionBoard,
        // active: splitPathBySlash(siteRoutes.editAdmissionBoard)[2],
        //     permission: sitePermissions.editAdmissionBoard,
        //     subPagesPermissions: [
        //         sitePermissions.editAdmissionBoard
        //     ]
        // },
        // {
        //     title: "Certificate Management",
        //     path: siteRoutes.editAdmissionCertificate,
        // active: splitPathBySlash(siteRoutes.editAdmissionCertificate)[2],
        //     permission: sitePermissions.editAdmissionCertificate,
        //     subPagesPermissions: [
        //         sitePermissions.editAdmissionCertificate
        //     ]
        // },
        {
          title: "Documents Master",
          path: siteRoutes.admissionManageDocumentsMasterListing,
          active: splitPathBySlash(
            siteRoutes.admissionManageDocumentsMasterListing
          )[3],
          permission: sitePermissions.admissionManageDocumentsMasterListing,
          subPagesPermissions: [
            sitePermissions.admissionManageDocumentsMasterListing,
          ],
        },
        {
          title: "Eligibility Templates Header",
          path: siteRoutes.admissioneligibilityTemplateHeadersListing,
          active: splitPathBySlash(
            siteRoutes.admissioneligibilityTemplateHeadersListing
          )[3],
          permission:
            sitePermissions.admissioneligibilityTemplateHeadersListing,
          subPagesPermissions: [
            sitePermissions.admissioneligibilityTemplateHeadersListing,
            sitePermissions.createAdmissioneligibilityTemplateHeaders,
          ],
        },
        {
          title: "Manage Quotas",
          path: siteRoutes.admissionQuotasListing,
          active: splitPathBySlash(siteRoutes.admissionQuotasListing)[3],
          permission: sitePermissions.admissionQuotasListing,
          subPagesPermissions: [
            sitePermissions.admissionQuotasListing,
            sitePermissions.createAdmissionQuotas,
          ],
        },
        {
          title: "Manage Subjects",
          path: siteRoutes.admissionSubjectManagementListing,
          active: splitPathBySlash(
            siteRoutes.admissionSubjectManagementListing
          )[3],
          permission: sitePermissions.admissionSubjectManagementListing,
          subPagesPermissions: [
            sitePermissions.admissionSubjectManagementListing,
            sitePermissions.createAdmissionSubjectManagement,
          ],
        },
        {
          title: "Manage Boards/Universities",
          path: siteRoutes.admissionBoardManagementListing,
          active: splitPathBySlash(
            siteRoutes.admissionBoardManagementListing
          )[3],
          permission: sitePermissions.admissionBoardManagementListing,
          subPagesPermissions: [
            sitePermissions.admissionBoardManagementListing,
            sitePermissions.createAdmissionBoardManagement,
          ],
        },
        {
          title: "Manage Certificate/Degree",
          path: siteRoutes.admissionCertificatemanagementListing,
          active: splitPathBySlash(
            siteRoutes.admissionCertificatemanagementListing
          )[3],
          permission: sitePermissions.admissionCertificateManagementListing,
          subPagesPermissions: [
            sitePermissions.admissionCertificateManagementListing,
            sitePermissions.createAdmissionCertificateManagement,
          ],
        },
        {
          title: "Merit Keys",
          path: siteRoutes.meritKeysListing,
          active: splitPathBySlash(siteRoutes.meritKeysListing)[3],
          permission: sitePermissions.meritKeysListing,
          subPagesPermissions: [
            sitePermissions.meritKeysListing,
            sitePermissions.createMeitKeys,
          ],
        },
        {
          title: "Merit List Formula",
          path: siteRoutes.meritListFormulaListing,
          active: splitPathBySlash(siteRoutes.meritListFormulaListing)[3],
          permission: sitePermissions.meritListFormulaListing,
          subPagesPermissions: [
            sitePermissions.meritKeysListing,
            sitePermissions.createMeitKeys,
          ],
        },
        {
          title: "Qoutas List",
          path: siteRoutes.quotasAdmissionListing,
          active: splitPathBySlash(siteRoutes.quotasAdmissionListing)[3],
          permission: sitePermissions.quotasAdmissionListing,
          subPagesPermissions: [
            sitePermissions.quotasAdmissionListing,
            sitePermissions.quotasAdmissionListing,
          ],
        },
        {
          title: "Manage Merit List",
          path: siteRoutes.manageMeritListListing,
          active: splitPathBySlash(siteRoutes.manageMeritListListing)[3],
          permission: sitePermissions.manageMeritListListing,
          subPagesPermissions: [
            sitePermissions.manageMeritListListing,
            sitePermissions.manageMeritListListing,
          ],
        },
      ],
      icon: GraduationSvg,
      isDropdownOpen: false,
    },
    {
      title: "Finance",
      path: splitPathBySlash(siteRoutes.financeDashboard)[2],
      subMenus: [
        {
          title: "Finance Dashboard",
          path: siteRoutes.financeDashboard,
          active: splitPathBySlash(siteRoutes.financeDashboard)[3],
          subPagesPermissions: [sitePermissions.financeDashboard],
          permission: sitePermissions.financeDashboard,
        },
        {
          title: "Student Financial Clearance",
          path: siteRoutes.studentFinancialClearanceListing,
          active: splitPathBySlash(
            siteRoutes.studentFinancialClearanceListing
          )[3],
          subPagesPermissions: [
            sitePermissions.studentFinancialClearanceListing,
          ],
          permission: sitePermissions.studentFinancialClearanceListing,
        },
        {
          title: "Check Challan Status",
          path: siteRoutes.checkFinancialChallanStatus,
          active: splitPathBySlash(siteRoutes.checkFinancialChallanStatus)[3],
          subPagesPermissions: [sitePermissions.checkFinancialChallanStatus],
          permission: sitePermissions.checkFinancialChallanStatus,
        },
        {
          title: "Template Programs Account",
          path: siteRoutes.templateProgramAccountCodeListing,
          active: splitPathBySlash(
            siteRoutes.templateProgramAccountCodeListing
          )[3],
          subPagesPermissions: [
            sitePermissions.templateProgramAccountCodeListing,
          ],
          permission: sitePermissions.templateProgramAccountCodeListing,
        },
        {
          title: "Fine slots",
          path: siteRoutes.fineSlotsListing,
          active: splitPathBySlash(siteRoutes.fineSlotsListing)[3],
          subPagesPermissions: [
            sitePermissions.fineSlotsListing,
            sitePermissions.createFineSlot,
          ],
          permission: sitePermissions.fineSlotsListing,
        },
        {
          title: "Voucher Types",
          path: siteRoutes.voucherTypesListing,
          active: splitPathBySlash(siteRoutes.voucherTypesListing)[3],
          subPagesPermissions: [
            sitePermissions.voucherTypesListing,
            sitePermissions.createVoucherType,
          ],
          permission: sitePermissions.voucherTypesListing,
        },
        {
          title: "Voucher Particulars",
          path: siteRoutes.voucherParticularListing,
          active: splitPathBySlash(siteRoutes.voucherParticularListing)[3],
          subPagesPermissions: [
            sitePermissions.voucherParticularListing,
            sitePermissions.createVoucherParticular,
          ],
          permission: sitePermissions.voucherParticularListing,
        },
        {
          title: "Voucher Template Header",
          path: siteRoutes.voucherTemplateHeaderListing,
          active: splitPathBySlash(siteRoutes.voucherTemplateHeaderListing)[3],
          subPagesPermissions: [
            sitePermissions.voucherTemplateHeaderListing,
            sitePermissions.createVoucherTemplateHeader,
          ],
          permission: sitePermissions.voucherTemplateHeaderListing,
        },
        {
          title: "Master Book",
          path: siteRoutes.masterBookListing,
          active: splitPathBySlash(siteRoutes.masterBookListing)[3],
          permission: sitePermissions.masterBookListing,
          subPagesPermissions: [
            sitePermissions.masterBookListing,
            sitePermissions.masterBookTransactionDetail,
            sitePermissions.showMasterBookTransactionDetail,
          ],
        },
        {
          title: "Applications",
          path: siteRoutes.financeApplicationsListing,
          active: splitPathBySlash(siteRoutes.financeApplicationsListing)[3],
          subPagesPermissions: [
            sitePermissions.financeApplicationsListing,
            sitePermissions.createFinanceApplications,
          ],
          permission: sitePermissions.financeApplicationsListing,
        },
        {
          title: "Application Templates",
          path: siteRoutes.applicationTemplateLinkListing,
          active: splitPathBySlash(
            siteRoutes.applicationTemplateLinkListing
          )[3],
          subPagesPermissions: [
            sitePermissions.applicationTemplateLinkListing,
            sitePermissions.createApplicationTemplateLink,
          ],
          permission: sitePermissions.applicationTemplateLinkListing,
        },
        {
          title: "Banks List",
          path: siteRoutes.banksListing,
          active: splitPathBySlash(siteRoutes.banksListing)[3],
          subPagesPermissions: [
            sitePermissions.banksListing,
            sitePermissions.createBank,
          ],
          permission: sitePermissions.banksListing,
        },
        {
          title: "Bank Transactions Logs",
          path: siteRoutes.bankTransactionLogsListing,
          active: splitPathBySlash(siteRoutes.bankTransactionLogsListing)[3],
          subPagesPermissions: [sitePermissions.bankTransactionLogsListing],
          permission: sitePermissions.bankTransactionLogsListing,
        },
        {
          title: "Bank Scroll Logs",
          path: siteRoutes.bankScrollLogsListing,
          active: splitPathBySlash(siteRoutes.bankScrollLogsListing)[3],
          subPagesPermissions: [sitePermissions.bankScrollLogsListing],
          permission: sitePermissions.bankScrollLogsListing,
        },
      ],
      icon: FinanceSvg,
      isDropdownOpen: false,
    },
    {
      title: "Hostel",
      path: "hostel",
      subMenus: [
        {
          title: "Hostel Management",
          path: siteRoutes.hostelListing,
          active: "hostel-management",
          permission: sitePermissions.hostelListing,
          subPagesPermissions: [
            sitePermissions.hostelListing,
            sitePermissions.createHostel,
          ],
        },
        {
          title: "Hostel Room Management",
          path: siteRoutes.hostelRoomsListing,
          active: "hostel-rooms-management",
          permission: sitePermissions.hostelRoomsListing,
          subPagesPermissions: [
            sitePermissions.hostelRoomsListing,
            sitePermissions.createHostelRoom,
          ],
        },
        {
          title: "Applicant Management",
          path: siteRoutes.applicantListing,
          active: "applicants-management",
          permission: sitePermissions.applicantListing,
          subPagesPermissions: [
            sitePermissions.applicantListing,
            sitePermissions.createApplicant,
          ],
        },
        {
          title: "Merit Management",
          path: siteRoutes.hostelMeritListing,
          active: "merit-management",
          permission: sitePermissions.hostelMeritListing,
          subPagesPermissions: [
            sitePermissions.hostelMeritListing,
            sitePermissions.createHostelMerit,
          ],
        },
        {
          title: "Sessions Management",
          path: siteRoutes.hostelSessionListing,
          active: "session-management",
          permission: sitePermissions.hostelSessionListing,
          subPagesPermissions: [
            sitePermissions.hostelSessionListing,
            sitePermissions.createHostelSession,
          ],
        },
        {
          title: "Finance Management",
          path: siteRoutes.hostelFinanceManagement,
          active: "finance-management",
          permission: sitePermissions.hostelFinanceManagement,
          subPagesPermissions: [sitePermissions.hostelFinanceManagement],
        },
        {
          title: "Applied Applicants",
          path: siteRoutes.appliedApplicant,
          active: "applied-applicant-management",
          permission: sitePermissions.appliedApplicant,
          subPagesPermissions: [sitePermissions.appliedApplicant],
        },
        {
          title: "Applicant Enrollment",
          path: siteRoutes.applicantEnrollment,
          active: "applicant-enrollment-management",
          permission: sitePermissions.applicantEnrollment,
          subPagesPermissions: [sitePermissions.applicantEnrollment],
        },
        {
          title: "Registered Applicant",
          path: siteRoutes.registeredApplicant,
          active: "registered-applicant-management",
          permission: sitePermissions.registeredApplicantList,
          subPagesPermissions: [sitePermissions.registeredApplicantList],
        },
        {
          title: "Hostel Applicant Merit List",
          path: siteRoutes.hostelApplicantMeritList,
          active: "hostel-applicant-merit-list-management",
          permission: sitePermissions.hostelApplicantMeritList,
          subPagesPermissions: [sitePermissions.hostelApplicantMeritList],
        },
      ],
      icon: GraduationSvg,
      isDropdownOpen: false,
    },

    //admin-career
    {
      title: "Careers",
      path: splitPathBySlash(siteRoutes.careerDesignationListing)[2],

      permission: sitePermissions.careerDesignationListing,

      subMenus: [
        {
          title: "Designation",
          path: siteRoutes.careerDesignationListing,
          active: "designation-management",
          permission: sitePermissions.careerDesignationListing,
          subPagesPermissions: [
            sitePermissions.careerDesignationListing,
            sitePermissions.createCareerDesignation,
          ],
        },
        {
          title: "Advertisement",
          path: siteRoutes.careerAdvertisementListing,
          active: "advertisement-management",
          permission: sitePermissions.careerAdvertisementListing,
          subPagesPermissions: [sitePermissions.careerAdvertisementListing],
        },
        {
          title: "Post Template",
          path: siteRoutes.careerPostTemplateListing,
          active: "post-template-management",
          permission: sitePermissions.careerPostTemplateListing,
          subPagesPermissions: [sitePermissions.createCareerPostTemplate],
        },
        {
          title: "Post",
          path: siteRoutes.careerPostListing,
          active: "post-management",
          permission: sitePermissions.careerPostListing,
          subPagesPermissions: [sitePermissions.careerPostListing],
        },
        {
          title: "Applicant",
          path: siteRoutes.careerApplicantListing,
          active: "applicant-management",
          permission: sitePermissions.careerApplicantListing,
          subPagesPermissions: [sitePermissions.careerApplicantListing],
        },
      ],
      icon: CareerSvg,
      isDropdownOpen: false,
    },
    {
      title: "Eportal Home",
      path: splitPathBySlash(siteRoutes.ePortalDashboard)[3],
      subMenus: [],
      icon: HomeSvg,
      link: siteRoutes.ePortalDashboard,
      isDropdownOpen: false,
      permission: sitePermissions.ePortalDashboard,
    },
    {
      title: "My Profile",
      path: splitPathBySlash(siteRoutes.viewEportalProfile)[3],
      subMenus: [],
      icon: ProfileSvg,
      link: siteRoutes.viewEportalProfile,
      isDropdownOpen: false,
      permission: sitePermissions.viewEportalProfile,
    },
    {
      title: "Generate Challan",
      path: splitPathBySlash(siteRoutes.ePortalChallanListing)[3],
      subMenus: [],
      icon: ChallanSvg,
      link: siteRoutes.ePortalChallanListing,
      permission: sitePermissions.ePortalChallanListing,
      isDropdownOpen: false,
    },
    {
      title: "My Vouchers",
      path: splitPathBySlash(siteRoutes.ePortalMyVouchers)[3],
      subMenus: [],
      icon: VoucherSvg,
      link: "",
      permission: sitePermissions.ePortalMyVouchers,
      isDropdownOpen: false,
    },

    // eportal-career
    {
      title: "Careers",
      path: splitPathBySlash(siteRoutes.eportalCareersListing)[3],

      subMenus: [
        {
          title: "Profile",
          path: siteRoutes.eportalCarrerProfile,
          active: "career-profile",
          permission: sitePermissions.eportalCarrerProfile,
          subPagesPermissions: [
            sitePermissions.eportalCarrerProfile,
            sitePermissions.eportalCarrerProfile,
          ],
        },
        {
          title: "Applied Jobs",
          path: siteRoutes.eportalAppliedJobListing,
          active: "e-portal-jobs",
          permission: sitePermissions.eportalAppliedJobListing,
          subPagesPermissions: [
            sitePermissions.eportalAppliedJobListing,
            sitePermissions.eportalAppliedJobListing,
          ],
        },
        {
          title: "Jobs",
          path: siteRoutes.eportalCareersListing,
          active: "e-portal-career",
          permission: sitePermissions.eportalCareersListing,
          subPagesPermissions: [
            sitePermissions.eportalCareersListing,
            sitePermissions.createEportalCareer,
          ],
        },
      ],
      icon: CareerSvg,

      isDropdownOpen: false,
    },
    {
      title: "Admissions",
      path: splitPathBySlash(siteRoutes.eportalAdmissionsListing)[3],
      subMenus: [],
      icon: AdmissionSvg,
      link: siteRoutes.eportalAdmissionsListing,
      permission: sitePermissions.eportalAdmissionsListing,
      isDropdownOpen: false,
    },
    {
      title: "Testing Services",
      path: splitPathBySlash(siteRoutes.ePortalDashboard)[3],
      subMenus: [],
      icon: TestingSvg,
      link: "",
      permission: sitePermissions.eportalTestingServiceListing,
      isDropdownOpen: false,
    },
    {
      title: "Hostel",
      path: splitPathBySlash(siteRoutes.eportalHostelPortal)[3],
      subMenus: [],
      icon: HostelSvg,
      link: siteRoutes.eportalHostelPortal,
      permission: sitePermissions.eportalHostelPortal,
      isDropdownOpen: false,
    },
    {
      title: "Short Courses",
      path: splitPathBySlash(siteRoutes.ePortalShortCoursesListing)[3],
      subMenus: [],
      icon: SideShortCoursesSvg,
      link: siteRoutes.ePortalShortCoursesListing,
      permission: sitePermissions.ePortalShortCoursesListing,
      isDropdownOpen: false,
    },
    {
      title: "Academics",
      path: "academics",
      subMenus: [
        // {
        //     title: "Sessions Management",
        //     path: siteRoutes.academicSessionListing,
        //     active: "academic-session-management",
        //     permission: sitePermissions.academicSessionListing,
        //     subPagesPermissions: [
        //         sitePermissions.academicSessionListing,
        //     ]
        // },
      ],
      icon: BooksSvg,
      isDropdownOpen: false,
    },

    {
      title: "Manage Merit List",
      path: "/manage-merit-list",
      subMenus: [],
      icon: MeritListSvg,
      isDropdownOpen: false,
    },
    {
      title: "Jobs Portal",
      path: "/manage-merit-list",
      subMenus: [],
      icon: JobsSvg,
      isDropdownOpen: false,
    },
    {
      title: "Short Courses",
      path: "/short-courses",
      subMenus: [],
      icon: CoursesSvg,
      isDropdownOpen: false,
    },
    {
      title: "My Privileges",
      path: "/my-privileges",
      subMenus: [],
      icon: PrivilegesSvg,
      isDropdownOpen: false,
    },
    {
      title: "Setting",
      path: "/settings",
      subMenus: [],
      icon: SettingsSolidSvg,
      isDropdownOpen: false,
    },
    {
      title: "System Administration",
      path: splitPathBySlash(siteRoutes.systemLogsListing)[3],
      subMenus: [
        {
          title: "Logs",
          path: siteRoutes.systemLogsListing,
          active: splitPathBySlash(siteRoutes.systemLogsListing)[3],
          permission: sitePermissions.systemLogsListing,
          subPagesPermissions: [sitePermissions.systemLogsListing],
        },
        {
          title: "Users",
          path: siteRoutes.systemUsersListing,
          active: splitPathBySlash(siteRoutes.systemUsersListing)[3],
          permission: sitePermissions.systemUsersListing,
          subPagesPermissions: [sitePermissions.systemUsersListing],
        },
        {
          title: "Menus",
          path: siteRoutes.systemMenusListing,
          active: splitPathBySlash(siteRoutes.systemMenusListing)[3],
          permission: sitePermissions.systemMenusListing,
          subPagesPermissions: [sitePermissions.systemMenusListing],
        },
        {
          title: "Un-Registered Menus",
          path: siteRoutes.unregisteredMenusListing,
          active: splitPathBySlash(siteRoutes.unregisteredMenusListing)[3],
          permission: sitePermissions.unregisteredMenusListing,
          subPagesPermissions: [sitePermissions.unregisteredMenusListing],
        },
        {
          title: "Role Management",
          path: siteRoutes.roleManagementListing,
          active: "roll-management",
          permission: sitePermissions.roleManagementListing,
          subPagesPermissions: [sitePermissions.roleManagementListing],
        },
        {
          title: "User Audit",
          path: siteRoutes.userAuditListing,
          active: splitPathBySlash(siteRoutes.userAuditListing)[3],
          permission: sitePermissions.userAuditListing,
          subPagesPermissions: [sitePermissions.userAuditListing],
        },
        {
          title: "WorkFlows",
          path: siteRoutes.workflowListing,
          active: splitPathBySlash(siteRoutes.workflowListing)[3],
          permission: sitePermissions.workflowListing,
          subPagesPermissions: [sitePermissions.workflowListing],
        },
      ],
      icon: AdministrationSvg,
      isDropdownOpen: false,
    },
  ];
  const getCurrentMenu = (): string => {
    const splittedPath: string[] = splitPathBySlash(pathname);
    const currentMenu = sidebarMenus.find(
      (menu) => menu.path === splittedPath[2]
    );
    return currentMenu?.subMenus?.length ? splittedPath[2] : splittedPath[3];
  };

  const getCurrentSubMenu = (): string => {
    const splittedPath: string[] = splitPathBySlash(pathname);
    return splittedPath[3];
  };
  const [currentMenu, setCurrentMenu] = useState(getCurrentMenu());
  const [currentSubMenu, setCurrentSubMenu] = useState(pathname);
  const navigate = useNavigate();

  const handleMainMenu = (menu: any, index: number) => {
    if (menu?.link) {
      navigate(menu.link);
      if (isCurrentWidthMinimum()) {
        setOpenSidebar(false);
      }
    }
    const { isDropdownOpen } = menu;
    menus.forEach((menu: any) => {
      menu.isDropdownOpen = false;
    });
    if (!isDropdownOpen) {
      menus[index].isDropdownOpen = true;
    }
    setMenus([...menus]);
  };
  const handleSubmenu = (path: string) => {
    if (isCurrentWidthMinimum()) {
      setOpenSidebar(false);
    }

    navigate(path);
  };

  useEffect(() => {
    setCurrentMenu(getCurrentMenu());
    setCurrentSubMenu(getCurrentSubMenu());
  }, [pathname]);

  const getSidebarMenus = () => {
    let menus: any[] = [];
    sidebarMenus.forEach((menu: any) => {
      const { subMenus } = menu;
      if (!menu?.permission) {
        if (!menu.subMenus.length) {
          // menus.push(menu);
        } else {
          const subMenusWithPermissions = subMenus.filter((subMenu: any) => {
            let doesPermissionExist: boolean = false;
            for (let permission of subMenu.subPagesPermissions) {
              const domainType = getDomainType();
              if (domainType === domains.mainDomain) {
                if (!subMenu?.superAdminOptional) {
                  if (hasAccess(permission)) {
                    doesPermissionExist = true;
                    break;
                  }
                }
              } else if (domainType === domains.subDomain) {
                if (!subMenu?.adminOptional) {
                  if (hasAccess(permission)) {
                    doesPermissionExist = true;
                    break;
                  }
                }
              }
            }

            return doesPermissionExist;
          });

          if (subMenusWithPermissions.length) {
            menus.push({
              ...menu,
              subMenus: subMenusWithPermissions,
            });
          }
        }
      } else {
        if (hasAccess(menu?.permission)) {
          menus.push(menu);
        }
      }
    });

    return menus;
  };

  const [menus, setMenus] = useState<any[]>([]);

  useEffect(() => {
    setMenus(getSidebarMenus());
  }, []);

  return (
    <SidebarMain>
      <SiteLogo>
        {isDarkTheme ? (
          <CyfyLogoDark className="icon" />
        ) : (
          <CyfyLogoSvg className="icon" />
        )}
      </SiteLogo>
      <Menus className="p-custom-scrollbar-4">
        {menus.map((item: any, index: number) => {
          const MenuIcon = item.icon;
          return (
            <div className="particular-menu" key={index}>
              <MenuItem
                active={currentMenu === item.path}
                onClick={() => handleMainMenu(item, index)}
                isDropdownOpen={item.isDropdownOpen}
              >
                <span className="dropdown-icon">
                  {item.subMenus.length ? (
                    <DropdownIcon className="icon" />
                  ) : (
                    ""
                  )}
                </span>
                <span className="menu-icon">
                  <MenuIcon className="icon" />
                </span>
                <span className="menu-text">{item.title}</span>
              </MenuItem>
              <DropdownMenu show={item.isDropdownOpen && item.subMenus.length}>
                {item.subMenus.map((menu: any, index: number) => {
                  return (
                    <DropdownMenuItem
                      active={menu.active === currentSubMenu}
                      key={index}
                      onClick={() => handleSubmenu(menu.path)}
                    >
                      <span className="menu-text">{menu.title}</span>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenu>
            </div>
          );
        })}
      </Menus>
    </SidebarMain>
  );
};

export default Sidebar;
