import { FC, Fragment, useState } from "react";
import {
  InstallmemtListingSection,
  InstallmemtListingMain,
  InstallmemtListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
interface VoucherTemplateHeadersListingProps {}

const InstallmemtListing: FC<VoucherTemplateHeadersListingProps> = ({}) => {
  const columns: string[] = ["Title", "Show Percentage", "Action"];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };
  const navigate = useNavigate();
  const goToAddInstallments = () => {
    navigate(siteRoutes.createFinanceInstallment);
  };
  const goToSlots = () => {
    navigate(siteRoutes.installmentSlotListing);
  };
  return (
    <InstallmemtListingMain>
      <InstallmemtListingTop>
        <div className="left">
          <span className="page-heading">Installments</span>
          <Breadcrumb />
        </div>
        <div className="right">
          <div className="create-fine-slot-btn" onClick={goToAddInstallments}>
            <button className="lg-rounded-btn">+ Add Installment</button>
          </div>
        </div>
      </InstallmemtListingTop>

      <InstallmemtListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2 Installments</td>
                <td>
                  {" "}
                  <div className="table-radio-field">
                    <div className="radio">
                      <label>No</label>
                      <input type="radio" />
                    </div>
                    <div className="radio">
                      <label>Yes</label>
                      <input type="radio" />
                    </div>
                  </div>
                </td>

                <td>
                  <div className="table-action-icons">
                    <div className="action-icon">
                      <EditTableSvg />
                    </div>
                    <div className="action-button">
                      <button className="black-btn" onClick={goToSlots}>
                        Manage Slots
                      </button>
                    </div>
                    <div className="action-icon">
                      <DeleteTableSvg />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </InstallmemtListingSection>
    </InstallmemtListingMain>
  );
};

export default InstallmemtListing;
