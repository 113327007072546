import { FC } from "react";
import { SADashboarMain } from "./style";

interface AdminDashboardProps { }

const AdminDashboard: FC<AdminDashboardProps> = ({ }) => {
    return (
        <SADashboarMain>
        </SADashboarMain>
    )
}

export default AdminDashboard;