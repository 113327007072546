export enum domains {
    superAdmin = 'localhost',
    mainDomain = 'localhost',
    subDomain = '192.168.100.44',
    subDeepDomain = 'eportal.org.umscloud.com'
}

export enum defaultRoutes {
    unAuthStudent = '/student/student-login',
    authStudent = '/private/student-e-portal',
    unAuthSuperAdmin = '/super-admin-login',

}  