import { FC, useEffect, useState } from "react";
import { AddParticularToChallanMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import MultiselectDropdown from "components/particles/forms/multiselect-dropdown";
import useComponentVisible from "hooks/click-outside";

interface EditTemplateBody {
  setOpen: Function;
}

const EditTemplateBody: FC<EditTemplateBody> = ({ setOpen }) => {
  const {
    isComponentVisible: showDropdown,
    setIsComponentVisible: setShowDropdown,
    ref: dropdownRef,
  } = useComponentVisible(false);

  return (
    <AddParticularToChallanMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8" expand={showDropdown}>
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Add Amount Slots Installment Particular</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Amount</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="number" />
                  </div>
                </div>
              </div>
            </div>
            {!showDropdown && (
              <div className="action-buttons">
                <div className="buttons">
                  <button
                    className="lg-rounded-btn gray"
                    type="reset"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>

                  <button
                    className="lg-rounded-btn"
                    type="button"
                    // onClick={onSubmit}
                  >
                    Save & Close
                  </button>
                </div>
              </div>
            )}
          </form>
        </ContentWrapper>
      </Container>
    </AddParticularToChallanMain>
  );
};

export default EditTemplateBody;
