import { FC } from "react";
import {
  CreateAdvertisementTop,
  CreateAdvertisementFormSection,
  CreateAdvertisementMain,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

interface CreateAdmissionCampaignProps {}

const CreateAdvertisement: FC<CreateAdmissionCampaignProps> = () => {
  return (
    <CreateAdvertisementMain>
      <CreateAdvertisementTop>
        <div className="heading">
          <span className="page-heading">Add Advertisement </span>
          <Breadcrumb />
        </div>
      </CreateAdvertisementTop>

      <CreateAdvertisementFormSection className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" placeholder="Enter Title" />
              </div>
            </div>
          </div>
          <div className="input-field">
            <label>Start Date</label>
            <div className="field-wrap">
              <div className="field">
                <input type="date" />
              </div>
            </div>
          </div>

          <div className="input-field">
            <label>End Date</label>
            <div className="field-wrap">
              <div className="field">
                <input type="date" />
              </div>
            </div>
          </div>
          <div className="radio-field">
            <label>Status</label>
            <div className="field-wrap">
              <div className="field">
                <input type="radio" id="active" />
                <label htmlFor="active">Active</label>
              </div>
              <div className="field">
                <input type="radio" id="deactivate" />
                <label htmlFor="deactivate">Deactivate</label>
              </div>
            </div>
          </div>
          <div className="radio-field">
            <label>Batch Type</label>
            <div className="field-wrap">
              <div className="field">
                <input type="radio" id="active" />
                <label htmlFor="active">Teaching</label>
              </div>
              <div className="field">
                <input type="radio" id="deactivate" />
                <label htmlFor="deactivate">Non-Teaching</label>
              </div>
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button">
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </CreateAdvertisementFormSection>
    </CreateAdvertisementMain>
  );
};

export default CreateAdvertisement;
