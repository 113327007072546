import { useDispatch } from "react-redux";
import { UnknownAction } from "redux";
import { SHARED_REDUCER_ACTIONS } from "store/reducers/shared.reducer";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { domains } from "utils/helpers/enums/shared.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { ROUTES_PERMISSIONS } from "utils/constants/pages-routes";

const useStore = () => {
    const dispatch = useDispatch();


    const dispatchAction = (type: string, payload?: any): void => {
        const dispatcher: UnknownAction = { type, payload };
        dispatch(dispatcher);
    }

    const setLoading = (isLoading: boolean): void => {
        dispatchAction(SHARED_REDUCER_ACTIONS.LOADING, isLoading);
    }

    const setToken = (payload: null | string): void => {
        dispatch({ type: SHARED_REDUCER_ACTIONS.SAVE_TOKEN, payload });
        if (payload) localStorage.setItem('token', payload)
    }

    const setUser = (payload: any): void => {
        dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_USER, payload);
        localStorage.setItem('user', JSON.stringify(payload));
    }

    const setPermissions = (permissions: any[]) => {
        const permissionsToSave = permissions.map(permission => {
            return typeof permission === 'string' ? permission : permission.name;
        });


        // permissionsToSave.push(sitePermissions.eportalHostelEnrollmentInfo);
        // permissionsToSave.push(sitePermissions.eportalHostelMedicalInfo);
        // permissionsToSave.push(sitePermissions.eportalHostelVisitorsInfo);
        // permissionsToSave.push(sitePermissions.eportalDownloadHostelVoucher);
        // permissionsToSave.push(sitePermissions.createAdmissionSession);
        // permissionsToSave.push(sitePermissions.viewEportalProfile);
        // permissionsToSave.push(sitePermissions.admissionSessionListing);
        // permissionsToSave.push(sitePermissions.admissionCampaignListing);
        // permissionsToSave.push(sitePermissions.createAdmissionCampaign);
        // permissionsToSave.push(sitePermissions.admissionStudentListing);
        // permissionsToSave.push(sitePermissions.admissionApplicantsListing);
        // permissionsToSave.push(sitePermissions.editAdmissionDocument);
        // permissionsToSave.push(sitePermissions.admissionDocumentListing);
        // permissionsToSave.push(sitePermissions.admissionTestTypesListing);
        // permissionsToSave.push(sitePermissions.createAdmissionTestType);
        // permissionsToSave.push(sitePermissions.studentRegListing);
        // permissionsToSave.push(sitePermissions.admissionMeritListing);
        // permissionsToSave.push(sitePermissions.createAdmissionOfferLetter);
        // permissionsToSave.push(sitePermissions.admissionOfferLetterListing);
        // permissionsToSave.push(sitePermissions.editAdmissionBoard);
        // permissionsToSave.push(sitePermissions.editAdmissionCertificate);
        // permissionsToSave.push(sitePermissions.admissionManageDocumentsMasterListing);
        // permissionsToSave.push(sitePermissions.admissioneligibilityTemplateHeadersListing);
        // permissionsToSave.push(sitePermissions.createAdmissioneligibilityTemplateHeaders);
        // permissionsToSave.push(sitePermissions.createAdmissioneligibilityTemplateBody);
        // permissionsToSave.push(sitePermissions.admissioneligibilityTemplateBodiesListing);
        // permissionsToSave.push(sitePermissions.createAdmissionQuotas);
        // permissionsToSave.push(sitePermissions.admissionQuotasListing);
        // permissionsToSave.push(sitePermissions.ePortalShortCoursesListing);
        // permissionsToSave.push(sitePermissions.ePortalGenerateChallan);
        // permissionsToSave.push(sitePermissions.eportalHostelMedicalInfo);
        // permissionsToSave.push(sitePermissions.eportalDownloadHostelVoucher);
        // permissionsToSave.push(sitePermissions.ePortalChallanListing);
        // permissionsToSave.push(sitePermissions.createHostel);
        // permissionsToSave.push(sitePermissions.createHostelRoom);
        // permissionsToSave.push(sitePermissions.hostelListing);
        // permissionsToSave.push(sitePermissions.hostelRoomsListing);
        // permissionsToSave.push(sitePermissions.applicantListing);
        // permissionsToSave.push(sitePermissions.createApplicant);
        // permissionsToSave.push(sitePermissions.hostelMeritListing);
        // permissionsToSave.push(sitePermissions.createHostelMerit);
        // permissionsToSave.push(sitePermissions.createHostelSession);
        // permissionsToSave.push(sitePermissions.hostelSessionListing);
        // permissionsToSave.push(sitePermissions.hostelFinanceManagement);
        // permissionsToSave.push(sitePermissions.appliedApplicant);
        // permissionsToSave.push(sitePermissions.applicantEnrollment);
        // permissionsToSave.push(sitePermissions.registeredApplicant);
        // permissionsToSave.push(sitePermissions.hostelApplicantMeritList);
        // permissionsToSave.push(sitePermissions.resultTypeListing);
        // permissionsToSave.push(sitePermissions.resultTypeCreate);
        // permissionsToSave.push(sitePermissions.certificateLinkListing);
        // permissionsToSave.push(sitePermissions.certificateLinkCreate);
        // permissionsToSave.push(sitePermissions.rollManagementCreate);
        // permissionsToSave.push(sitePermissions.roleManagementListing);
        // permissionsToSave.push(sitePermissions.boardManagementListing);
        // permissionsToSave.push(sitePermissions.boardManagementCreate);
        // permissionsToSave.push(sitePermissions.subjectManagementListing);
        // permissionsToSave.push(sitePermissions.subjectManagementCreate);
        // permissionsToSave.push(sitePermissions.certificateManagementListing);
        // permissionsToSave.push(sitePermissions.certificateManagementCreate);
        // permissionsToSave.push(sitePermissions.superAdminManagementListing);
        // permissionsToSave.push(sitePermissions.superAdminManagementCreate);
        // permissionsToSave.push(sitePermissions.superRoleManagementListing);
        // permissionsToSave.push(sitePermissions.superRoleManagementCreate);
        // permissionsToSave.push(sitePermissions.permissionManagementListing);
        // permissionsToSave.push(sitePermissions.permissionManagementCreate);
        // permissionsToSave.push(sitePermissions.moduleManagement);
        // permissionsToSave.push(sitePermissions.linkedSpecializationListing);
        // permissionsToSave.push(sitePermissions.createLinkedSpecialization);
        // permissionsToSave.push(sitePermissions.linkedProgramEntryTestListing);
        // permissionsToSave.push(sitePermissions.createLinkedProgramEntryTest);
        // permissionsToSave.push(sitePermissions.academicSessionOrganizationListing);
        // permissionsToSave.push(sitePermissions.createAcademicSessionOrganization);
        // permissionsToSave.push(sitePermissions.admissionProgramListing);
        // permissionsToSave.push(sitePermissions.admissionSubjectManagementListing);
        // permissionsToSave.push(sitePermissions.createAdmissionSubjectManagement);
        // permissionsToSave.push(sitePermissions.admissionBoardManagementListing);
        // permissionsToSave.push(sitePermissions.createAdmissionBoardManagement);
        // permissionsToSave.push(sitePermissions.admissionCertificateManagementListing);
        // permissionsToSave.push(sitePermissions.createAdmissionCertificateManagement);
        // permissionsToSave.push(sitePermissions.linkedProgramAdmissionListing);
        // permissionsToSave.push(sitePermissions.meritKeysListing);
        // permissionsToSave.push(sitePermissions.createMeitKeys);
        // permissionsToSave.push(sitePermissions.meritListFormulaListing);
        // permissionsToSave.push(sitePermissions.createMeritListFormula);
        // permissionsToSave.push(sitePermissions.majorCategoriesListing);
        // permissionsToSave.push(sitePermissions.CreateMajorCategories);
        // permissionsToSave.push(sitePermissions.minorCategoriesListing);
        // permissionsToSave.push(sitePermissions.createMinorCategories);
        // permissionsToSave.push(sitePermissions.roomsListing);
        // permissionsToSave.push(sitePermissions.createRooms);
        // permissionsToSave.push(sitePermissions.forgetPassword);
        // permissionsToSave.push(sitePermissions.codeVerification);
        // permissionsToSave.push(sitePermissions.codeVerification);
        //  permissionsToSave.push(sitePermissions.ePortalMyVouchers);
        localStorage.setItem('permissions', JSON.stringify(permissionsToSave));
        // sitePermissions.createOrgStructure, sitePermissions.orgStructureListing
        dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_PERMISSIONS, [...permissionsToSave]);
    }

    const getPermissions = () => {
        const permissions = localStorage.getItem('permissions');
        if (permissions) {
            return JSON.parse(permissions);
        } else {
            return [];
        }
    }

    const getToken = (): string | null => {
        return localStorage.getItem('token');
    }

    const setRole = (role: any): void => {
        localStorage.setItem('role', JSON.stringify(role));
    }

    const hasAccess = (permission: string): boolean => {
        const permissions = getPermissions();
        return permissions.includes(permission);
    }

    const getRole = (): any => {
        const role = localStorage.getItem('role');
        if (role) {
            return JSON.parse(role);
        } else {
            return null;
        }
    }

    const logout = () => {
        dispatchAction(SHARED_REDUCER_ACTIONS.LOGOUT);
        localStorage.clear();
    }

    const setDarkTheme = (isDarkTheme: boolean) => {
        if (isDarkTheme) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
        dispatchAction(SHARED_REDUCER_ACTIONS.THEME_CHANGE, isDarkTheme);
    }

    const getDomainDefaultUnAuthRoute: any = () => {
        const domainType = getDomainType();
        if (domainType == domains.mainDomain) {
            return siteRoutes.superAdminLogin
        } else {
            return siteRoutes.ePortalLogin;
        }
    }

    const getUser = (): any => {
        const user = localStorage.getItem('user');
        if (user) {
            return JSON.parse(user);
        } else {
            return null;
        }
    }

    const getDomainDefaultAuthRoute: any = () => {
        const domainType = getDomainType();
        if (domainType == domains.mainDomain) {
            return siteRoutes.superAdminDashboard
        } else {
            const userRole = getRole();
            if (userRole?.guard_name == ROLES.ADMIN) {
                if (hasAccess(sitePermissions.adminDashboard)) {
                    return siteRoutes.adminDashboard;
                } else {
                    const permissions = getPermissions();
                    const firstPermissionRoute = ROUTES_PERMISSIONS.find((p: any) => p.permission === permissions[0]);
                    return firstPermissionRoute?.path;
                }
            } else if (userRole?.guard_name == ROLES.USER) {
                return siteRoutes.ePortalDashboard;
            }
        }
    }

    const isSuperAdmin = () => {
        const role = getRole();
        return role?.guard_name === ROLES.SUPER_ADMIN;
    }

    const setOrganization = (payload: any) => {
        dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_ORGANIZATION, payload);
        localStorage.setItem('organization', JSON.stringify(payload));
    }

    // (domain === '192.168.100.44' || domain === '192.168.0.196' || domain === domain.replace(domain.split('.')[0], 'org'))

    const getDomainType = () => {
        const domain = getDomain();
        if (domain === 'localhost' || domain === 'umsoncloud.com') {
            return domains.mainDomain;
        } else {
            return domains.subDomain
        }
    }
    const getDomain = (): string => {
        return window.location.hostname;
    }

    const getOrganization = (): any => {
        const org = localStorage.getItem('organization');
        if (org) {
            return JSON.parse(org);
        } else {
            return null;
        }
    }

    return {
        setToken,
        setLoading,
        dispatchAction,
        setDarkTheme,
        logout,
        setUser,
        getDomainDefaultUnAuthRoute,
        getDomainDefaultAuthRoute,
        setPermissions,
        hasAccess,
        getDomainType,
        getPermissions,
        getToken,
        setOrganization,
        getOrganization,
        getRole,
        setRole,
        isSuperAdmin
    }
}

export default useStore;