import { FC, Fragment, useEffect, useState } from "react";
import {
  AdvertisementListingMain,
  AdvertisementListingSection,
  AdvertisementListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  DownloadTableSvg,
} from "assets/images/common/svgs";

import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const AdvertisementListing: FC = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "BatchTitle",
    "Start Date",
    "End Date",
    "Status",
    "Batch Type",
    "Action",
  ];

  const navigate = useNavigate();
  const goToCreateAdvertisement = () => {
    navigate(siteRoutes.createCareerAdvertisement);
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  return (
    <AdvertisementListingMain>
      <AdvertisementListingTop>
        <div className="left">
          <span className="page-heading">Advertisements</span>
          <Breadcrumb />
        </div>
        <div className="right">
          <div className="create-org-btn">
            <button
              className="lg-rounded-btn"
              onClick={goToCreateAdvertisement}
            >
              + Add Advertisement
            </button>
          </div>
        </div>
      </AdvertisementListingTop>

      <AdvertisementListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>IPOP</td>
                <td>2024-02-18</td>
                <td>2024-04-08</td>
                <td>
                  <div className="table-radio-field">
                    <div className="radio">
                      <input
                        type="radio"
                        name={`ask-for-sub`}
                        id={`ask-for-subjects-yes-`}
                      />
                      <label htmlFor={`ask-for-subjects-yes-`}>Active</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        name={`ask-for-subjects-`}
                        id={`ask-for-subjects-no-`}
                      />
                      <label htmlFor={`ask-for-subjects-no-`}>Non-Active</label>
                    </div>
                  </div>
                </td>
                <td>Teaching</td>

                <td>
                  <div className="table-action-icons">
                    <div className="action-icon">
                      <DownloadTableSvg />
                    </div>

                    <div className="action-icon">
                      <DeleteTableSvg />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination onPageChange={onPageChange} {...pagination} />
        </Fragment>
      </AdvertisementListingSection>
    </AdvertisementListingMain>
  );
};

export default AdvertisementListing;
