import { FC, Fragment, useState } from "react";
import { ShowTransactionDetailListingSection, ShowTransactionDetailMain, ShowTransactionDetailTop, TransactionDropdownDetail, TransactionDropdownHeader, TransactionDropdownSection } from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { DeleteTableSvg, DownArrowLightgrayMediumSvg, ExcelSvg, PdfSvg, SearchFieldSvg } from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import AddParticularToChallan from "./components/add-particulars-to-challan";

interface MasterBookTransactionDetailProps { }

const MasterBookTransactionDetail: FC<MasterBookTransactionDetailProps> = ({ }) => {
    const navigate = useNavigate();
    const [showTransactionDropdown, setShowTransactionDropdown] = useState<boolean>(false);
    const [openAddParticularToChallan, setOpenAddParticularChallan] = useState<boolean>(false);
    const columns: string[] = [
        "Particular ID",
        "Particular",
        "Amount",
        "Action",
    ]

    const toggleTransactionDropdown = () => {
        setShowTransactionDropdown(!showTransactionDropdown);
    }
    return (
        <ShowTransactionDetailMain>
            <ShowTransactionDetailTop>
                <div className="left">
                    <span className="page-heading">Transaction Details</span>
                    <Breadcrumb />
                </div>
                <div className="right">
                    <div className="add-btn">
                        <button className="lg-rounded-btn" onClick={() => setOpenAddParticularChallan(true)}>+ Add Voucher Particulars</button>
                    </div>
                </div>
            </ShowTransactionDetailTop>

            <TransactionDropdownSection className="content-radius-shadow">
                <TransactionDropdownHeader showFilterDropdown={showTransactionDropdown}>
                    <span className="filter-heading">Application: BWP-F24-201-29643, Challan: 2431159088</span>
                    <span className="dropdown-arrow cp" onClick={toggleTransactionDropdown}>
                        <DownArrowLightgrayMediumSvg className="icon" />
                    </span>
                </TransactionDropdownHeader>
                {showTransactionDropdown ?
                    <TransactionDropdownDetail>
                        <div className="top-section">
                            <div className="total-amount">
                                <div className="total-amount-box">
                                    <div className="title">Total Amount</div>
                                    <div className="amount">1000</div>
                                </div>
                            </div>
                            <div className="discount-field">
                                <div className="input-field">
                                    <label htmlFor="">Fee Discount/Adjustment</label>
                                    <div className="field-wrap">
                                        <div className="field">
                                            <select name="" id="">
                                                <option value="">Select Fee Discount/Adjustment</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-button">
                                    <button>Submit</button>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-section">
                            <div className="form-section">
                                <div className="common-fields">
                                    <div className="input-field">
                                        <label htmlFor="">Due date</label>
                                        <div className="field-wrap">
                                            <div className="field">
                                                <input type="date" name="" id="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-field">
                                        <label htmlFor="">Remarks</label>
                                        <div className="field-wrap">
                                            <div className="field">
                                                <select>
                                                    <option value="">All Remarks</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="submit-button">
                                    <button>Submit</button>
                                </div>
                            </div>
                        </div>
                    </TransactionDropdownDetail>
                    : ''}
            </TransactionDropdownSection>

            <ShowTransactionDetailListingSection className="content-radius-shadow">
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        <div className="export-btn">
                            <span>
                                <PdfSvg className="icon" />
                            </span>
                            <span className="text">PDF</span>
                        </div>
                        <div className="export-btn">
                            <span>
                                <ExcelSvg className="icon" />
                            </span>
                            <span className="text">Excel</span>
                        </div>
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => {
                                return <tr key={index}>
                                    <td>22916779</td>
                                    <td>Admission Processing Fee</td>
                                    <td>4,000</td>
                                    <td>
                                        <div className="table-action-icons">
                                            <div className="action-icon">
                                                <button className="md-action-btn">Audit Trail</button>
                                            </div>
                                            <div className="action-icon">
                                                <DeleteTableSvg />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    <Pagination page={1} onPageChange={e => console.log(e)} per_page={10} totalRecords={40} />
                </Fragment>
            </ShowTransactionDetailListingSection>
            <Fragment>
                {openAddParticularToChallan ? <AddParticularToChallan setOpen={setOpenAddParticularChallan} /> : ''}
            </Fragment>
        </ShowTransactionDetailMain>
    )
}

export default MasterBookTransactionDetail;