import { FC, useEffect, useState } from "react";
import {
  CreateInstallmentSlotsForm,
  CreateInstallmentSlotsMain,
  CreateInstallmentSlotsTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";

interface CreateVoucherTemplateHeaderProps {}

const CreateInstallmentSlots: FC<CreateVoucherTemplateHeaderProps> = ({}) => {
  return (
    <CreateInstallmentSlotsMain>
      <CreateInstallmentSlotsTop>
        <div className="heading">
          <span className="page-heading">Add Installment Slots</span>
          <Breadcrumb />
        </div>
      </CreateInstallmentSlotsTop>
      <CreateInstallmentSlotsForm className="content-radius-shadow">
        <div className="common-fields">
          <div className="input-field">
            <label htmlFor="application">Installment No.</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" placeholder="1" />
              </div>
            </div>
          </div>
          <div className="input-field">
            <label htmlFor="application">Due Date</label>
            <div className="field-wrap">
              <div className="field">
                <input type="date" />
              </div>
            </div>
          </div>
          <div className="input-field">
            <label htmlFor="application">Percentage</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" />
              </div>
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button">
              Cancel
            </button>
            <button className="lg-rounded-btn">Submit</button>
          </div>
        </div>
      </CreateInstallmentSlotsForm>
    </CreateInstallmentSlotsMain>
  );
};

export default CreateInstallmentSlots;
