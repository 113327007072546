import { FC, useEffect, useState } from "react";
import { QualificationTestMain, ContentWrapper, Container } from "./style";
import { CloseMediumSvg, SmallUploadSvg } from "assets/images/common/svgs";

import { AddQualificationDTO } from "utils/helpers/models/e-portal/add-qualification.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";

import { useLocation, useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

interface AddQualificationProps {
  setOpen: Function;
}

const AddQualification: FC<AddQualificationProps> = ({ setOpen }) => {
  const [formData, setFormData] = useState<AddQualificationDTO>(
    new AddQualificationDTO()
  );
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    if (state?.qualification)
      navigate(siteRoutes.ePortalEditProfile, { state: {} });
    setOpen(false);
  };

  return (
    <QualificationTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Academic Information </span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Certificate/Degree</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Certificate/Degree</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Roll No./Reg No.</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="number" name="" id="" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Board/University</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Board</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Passing Year</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Passing Year</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Total Marks/GPA</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="number" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Obtained Marks</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="number" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Result</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="reset">
                  Reset
                </button>

                <button className="lg-rounded-btn black" type="submit">
                  Save & Add More
                </button>
                <button className="lg-rounded-btn" type="button">
                  Save
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </QualificationTestMain>
  );
};

export default AddQualification;
