import { FC, Fragment, useEffect, useState } from "react";
import {
  JobListingLeft,
  JobListingMain,
  JobListingRight,
  JobListingTop,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  ProgramActionSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useSelector } from "react-redux";
import DataNotFound from "components/particles/table/data-not-found";
import useUtils from "hooks/useUtils";

const CareerListing: FC = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const [search, setSearch] = useState("");
  const { getDateFromDateTime } = useUtils();
  const [data, setData] = useState<any[]>([]);
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const title = ["Job Type", "Experience", "Qualification"];
  const something = [
    ["Full-time", "Part-time", "Contract", "Daily Wages"], // Options for "Job Type"
    ["Fresh", "1 Year", "2 Year", "3 Year"], // Options for "Experience"
    ["Intermediate", "Graduation", "Master Degree", "High School"], // Options for "Qualification"
  ];

  const goToEditCareer = () => {
    navigate(siteRoutes.createEportalCareer);
  };
  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  return (
    <JobListingMain>
      <JobListingTop>
        <div className="left">
          <span className="page-heading">Job Listing</span>
          <Breadcrumb />
        </div>
        <div className="right"></div>
      </JobListingTop>

      <div className="flex">
        <JobListingLeft>
          <div className="search-flex">
            <div className="search-input">
              <SearchFieldSvg className="search-icon" />
              <input type="text" placeholder="Job Title" />
            </div>
          </div>

          <div className="job-categories">
            {title.map((item, index) => {
              return (
                <div key={index}>
                  <div className="main-title">{item}</div>{" "}
                  <div className="select-option">
                    {something[index].map(
                      (
                        option,
                        optionIndex /* Map over the corresponding options */
                      ) => (
                        <div key={optionIndex} className="option">
                          <input
                            type="checkbox"
                            id={`checkbox-${index}-${optionIndex}`}
                          />
                          <label htmlFor={`checkbox-${index}-${optionIndex}`}>
                            {option}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </JobListingLeft>
        <JobListingRight>
          <div className="left-top">
            <div>
              <span className="main-heading">Active Jobs</span>
            </div>
            <div className="select-field">
              <select name="" id="">
                <option value="">Newest First</option>
                <option value="">Oldest First</option>
              </select>
            </div>
          </div>

          <div className="job-description-main">
            <div className="title-main">
              <span className="description-title">Junior Programmer</span>
              <div>
                <p className="job-type">Contract</p>
              </div>
            </div>
            <div>
              <p className="job-description">
                Bachelor's degree in Computer Science/IT/MIS/E-Commerce /
                Software Engineering / Computer Engineering awarded after 16
                years of education or equivalent from an HEC recognized
                university / institution. Must be fluent in written and spoken
                English.
              </p>
            </div>
            <div>
              <p className="department">
                Department / Field
                <span className="office"> Registrar Office</span>
              </p>
            </div>

            <div className="job-details">
              <div className="detail">
                <p className="job-title">Last Date :</p>
                <p className="job-data"> 2020-02-29</p>
              </div>
              <div className="detail">
                <p className="job-title">Scale/Equivalent :</p>
                <p className="job-data">BPS 16</p>
              </div>
              <div className="detail">
                <p className="job-title">Max Age Limit :</p>
                <p className="job-data">45</p>
              </div>
              <div className="detail">
                <p className="job-title">Job Batch :</p>
                <p className="job-data">IPB-186 27.02.23</p>
              </div>
            </div>
            <div className="submit-buttons">
              <div className="buttons">
                <button
                  type="button"
                  className="lg-rounded-btn"
                  onClick={goToEditCareer}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </JobListingRight>
      </div>
    </JobListingMain>
  );
};

export default CareerListing;
