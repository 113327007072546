import { FC, useState, useEffect } from "react";
import { CreatePostSection, CreatePostMain, CreatePostTop } from "./style";
import Breadcrumb from "components/particles/breadcrumb";

const CreatePost: FC = () => {
  const handleFileUpload = (event: any) => {
    const { name, files } = event.target;
    const file = files[0];
  };

  return (
    <CreatePostMain>
      <CreatePostTop>
        <div className="left">
          <span className="page-heading">Add Post</span>
          <Breadcrumb />
        </div>
        <div className="right"></div>
      </CreatePostTop>

      <CreatePostSection className="p-custom-scrollbar-8">
        <form>
          <div className="common-fields">
            <div className="input-field ">
              <label>Select Template</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Template</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Advertisement Number</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" placeholder="Advertisement Number" />
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Select Batch</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Batch</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field">
              <label>Case Number</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="number" placeholder="Case Number" />
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>Post for Women</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" placeholder="Post for Women" />
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>Post for Disabled Person </label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" placeholder="Post for Disabled Person" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field">
              <label>Post for Minorities </label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" placeholder="Post for Minorities" />
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>Special Quota </label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" placeholder="Special Quota" />
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Closing Date</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="date" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field">
              <label>in House Closing Date </label>
              <div className="field-wrap">
                <div className="field">
                  <input type="date" />
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>For internal Employees? </label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">No</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field">
              <label>Allow admin users to apply for this post</label>
              <div className="field-wrap">
                <div className="field">
                  <input type="text" placeholder="In House Users" />
                </div>
              </div>
            </div>
          </div>
          <div className="common-fields">
            <div className="input-field ">
              <label>Select Departments</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Department</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Select Job Type</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Department</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-field ">
              <label>Select Campus</label>
              <div className="field-wrap">
                <div className="field">
                  <select name="" id="">
                    <option value="">Select Campus</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="action-buttons">
            <div className="buttons">
              <button className="lg-rounded-btn gray" type="button">
                Reset
              </button>
              <button className="lg-rounded-btn">Save & Exit</button>
            </div>
          </div>
        </form>
      </CreatePostSection>
    </CreatePostMain>
  );
};

export default CreatePost;
