import { domains } from "utils/helpers/enums/shared.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

export const ROUTES_PERMISSIONS = [
  {
    path: siteRoutes.createOrganization,
    permission: sitePermissions.createOrganization,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.organizationListing,
    permission: sitePermissions.organizationListing,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.viewOrganization,
    permission: sitePermissions.viewOrganization,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.createAsset,
    permission: sitePermissions.createAsset,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.assetsListing,
    permission: sitePermissions.assetListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createBuilding,
    permission: sitePermissions.createBuilding,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.buildingListing,
    permission: sitePermissions.buildingListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createProgram,
    permission: sitePermissions.createProgram,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.programListing,
    permission: sitePermissions.programListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createOrgStructure,
    permission: sitePermissions.createOrgStructure,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.orgStructureListing,
    permission: sitePermissions.orgStructureListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createStructureType,
    permission: sitePermissions.createStructureType,
    domains: [domains.mainDomain, domains.subDomain],
  },
  {
    path: siteRoutes.structureTypeListing,
    permission: sitePermissions.structureTypeListing,
    domains: [domains.mainDomain, domains.subDomain],
  },
  {
    path: siteRoutes.resultTypeListing,
    permission: sitePermissions.resultTypeListing,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.resultTypeCreate,
    permission: sitePermissions.resultTypeCreate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.superAdminLogin,
    permission: sitePermissions.superAdminLogin,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.superAdminDashboard,
    permission: sitePermissions.superAdminDashboard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.adminDashboard,
    permission: sitePermissions.adminDashboard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.adminLogin,
    permission: sitePermissions.adminLogin,
    domains: [domains.subDomain],
  },
  //new routes
  {
    path: siteRoutes.createHostel,
    permission: sitePermissions.createHostel,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.hostelListing,
    permission: sitePermissions.hostelListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.hostelRoomsListing,
    permission: sitePermissions.hostelRoomsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createHostelRoom,
    permission: sitePermissions.createHostelRoom,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.applicantListing,
    permission: sitePermissions.applicantListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createApplicant,
    permission: sitePermissions.createApplicant,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.hostelMeritListing,
    permission: sitePermissions.hostelMeritListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createHostelMerit,
    permission: sitePermissions.createHostelMerit,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.hostelSessionListing,
    permission: sitePermissions.hostelSessionListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createHostelSession,
    permission: sitePermissions.createHostelSession,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.hostelFinanceManagement,
    permission: sitePermissions.hostelFinanceManagement,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.appliedApplicant,
    permission: sitePermissions.appliedApplicant,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.applicantEnrollment,
    permission: sitePermissions.applicantEnrollment,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.registeredApplicant,
    permission: sitePermissions.registeredApplicantList,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.hostelApplicantMeritList,
    permission: sitePermissions.hostelApplicantMeritList,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalLogin,
    permission: sitePermissions.ePortalDashboard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalRegister,
    permission: sitePermissions.ePortalDashboard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalResetPassword,
    permission: sitePermissions.ePortalDashboard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalDashboard,
    permission: sitePermissions.ePortalDashboard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalChallanListing,
    permission: sitePermissions.ePortalChallanListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalShortCoursesListing,
    permission: sitePermissions.ePortalShortCoursesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalShortCoursesListing,
    permission: sitePermissions.ePortalShortCoursesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.ePortalEditProfile,
    permission: sitePermissions.ePortalEditProfile,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.eportalHostelPortal,
    permission: sitePermissions.eportalHostelPortal,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.eportalAdmissionsListing,
    permission: sitePermissions.eportalAdmissionsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.academicSessionListing,
    permission: sitePermissions.academicSessionListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionSession,
    permission: sitePermissions.createAdmissionSession,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.viewEportalProfile,
    permission: sitePermissions.viewEportalProfile,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionSessionListing,
    permission: sitePermissions.admissionSessionListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionCampaign,
    permission: sitePermissions.createAdmissionCampaign,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionCampaignListing,
    permission: sitePermissions.admissionCampaignListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionStudentListing,
    permission: sitePermissions.admissionStudentListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionApplicantsListing,
    permission: sitePermissions.admissionApplicantsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.editAdmissionDocument,
    permission: sitePermissions.editAdmissionDocument,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionDocumentListing,
    permission: sitePermissions.admissionDocumentListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionTestTypesListing,
    permission: sitePermissions.admissionTestTypesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionTestType,
    permission: sitePermissions.createAdmissionTestType,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.studentRegListing,
    permission: sitePermissions.studentRegListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionMeritListing,
    permission: sitePermissions.admissionMeritListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionOfferLetterListing,
    permission: sitePermissions.admissionOfferLetterListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionMeritListing,
    permission: sitePermissions.admissionMeritListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionOfferLetterListing,
    permission: sitePermissions.admissionOfferLetterListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionOfferLetter,
    permission: sitePermissions.createAdmissionOfferLetter,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.editAdmissionBoard,
    permission: sitePermissions.editAdmissionBoard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.editAdmissionCertificate,
    permission: sitePermissions.editAdmissionCertificate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionManageDocumentsMasterListing,
    permission: sitePermissions.admissionManageDocumentsMasterListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissioneligibilityTemplateHeadersListing,
    permission: sitePermissions.admissioneligibilityTemplateHeadersListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissioneligibilityTemplateHeaders,
    permission: sitePermissions.createAdmissioneligibilityTemplateHeaders,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissioneligibilityTemplateBodiesListing,
    permission: sitePermissions.admissioneligibilityTemplateBodiesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissioneligibilityTemplateBody,
    permission: sitePermissions.createAdmissioneligibilityTemplateBody,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionQuotasListing,
    permission: sitePermissions.admissionQuotasListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionQuotas,
    permission: sitePermissions.createAdmissionQuotas,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.certificateLinkListing,
    permission: sitePermissions.certificateLinkListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.certificateLinkCreate,
    permission: sitePermissions.certificateLinkCreate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.rollManagementCreate,
    permission: sitePermissions.rollManagementCreate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.roleManagementListing,
    permission: sitePermissions.roleManagementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.boardManagementListing,
    permission: sitePermissions.boardManagementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.boardManagementCreate,
    permission: sitePermissions.boardManagementCreate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.subjectManagementListing,
    permission: sitePermissions.subjectManagementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.subjectManagementCreate,
    permission: sitePermissions.subjectManagementCreate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.certificateManagementListing,
    permission: sitePermissions.certificateManagementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.certificateManagementCreate,
    permission: sitePermissions.certificateManagementCreate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.superAdminManagementListing,
    permission: sitePermissions.superAdminManagementListing,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.superAdminManagementCreate,
    permission: sitePermissions.superAdminManagementCreate,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.superRoleManagementListing,
    permission: sitePermissions.superRoleManagementListing,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.superRoleManagementCreate,
    permission: sitePermissions.superRoleManagementCreate,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.permissionManagementListing,
    permission: sitePermissions.permissionManagementListing,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.permissionManagementCreate,
    permission: sitePermissions.permissionManagementCreate,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.moduleManagement,
    permission: sitePermissions.moduleManagement,
    domains: [domains.mainDomain],
  },
  {
    path: siteRoutes.linkedSpecializationListing,
    permission: sitePermissions.linkedSpecializationListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createLinkedSpecialization,
    permission: sitePermissions.createLinkedSpecialization,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.linkedProgramEntryTestListing,
    permission: sitePermissions.linkedProgramEntryTestListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createLinkedProgramEntryTest,
    permission: sitePermissions.createLinkedProgramEntryTest,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.academicSessionOrganizationListing,
    permission: sitePermissions.academicSessionOrganizationListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAcademicSessionOrganization,
    permission: sitePermissions.createAcademicSessionOrganization,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionProgramListing,
    permission: sitePermissions.admissionProgramListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionSubjectManagementListing,
    permission: sitePermissions.admissionSubjectManagementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionSubjectManagement,
    permission: sitePermissions.createAdmissionSubjectManagement,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionBoardManagementListing,
    permission: sitePermissions.admissionBoardManagementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionBoardManagement,
    permission: sitePermissions.createAdmissionBoardManagement,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.admissionCertificatemanagementListing,
    permission: sitePermissions.admissionCertificateManagementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionCertificateManagement,
    permission: sitePermissions.createAdmissionCertificateManagement,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.meritKeysListing,
    permission: sitePermissions.meritKeysListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createMeritKeys,
    permission: sitePermissions.createMeitKeys,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.meritListFormulaListing,
    permission: sitePermissions.meritListFormulaListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createMeritListFormula,
    permission: sitePermissions.createMeritListFormula,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.majorCategoriesListing,
    permission: sitePermissions.majorCategoriesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.CreateMajorCategories,
    permission: sitePermissions.CreateMajorCategories,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.minorCategoriesListing,
    permission: sitePermissions.minorCategoriesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createMinorCategories,
    permission: sitePermissions.createMinorCategories,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.roomsListing,
    permission: sitePermissions.roomsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createRooms,
    permission: sitePermissions.createRooms,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.forgetPassword,
    permission: sitePermissions.forgetPassword,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.codeVerification,
    permission: sitePermissions.codeVerification,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createEportalAdmissionList,
    permission: sitePermissions.createEportalAdmissionList,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.quotasAdmissionListing,
    permission: sitePermissions.quotasAdmissionListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createQuotasAdmissionList,
    permission: sitePermissions.createQuotasAdmissionList,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.manageMeritListListing,
    permission: sitePermissions.manageMeritListListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createAdmissionManageMeritList,
    permission: sitePermissions.createAdmissionManageMeritList,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.qoutasListProgramListing,
    permission: sitePermissions.qoutasListProgramListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.eligibilityTemplatesListing,
    permission: sitePermissions.eligibilityTemplatesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.eligibilityTemplatesCreate,
    permission: sitePermissions.eligibilityTemplatesCreate,
    domains: [domains.subDomain],
  },
  // {
  //   path: siteRoutes.linkedProgramAdmissionListing,
  //   permission: sitePermissions.linkedProgramAdmissionListing,
  //   domains: [domains.subDomain],
  // },
  {
    path: siteRoutes.programLinkMeritList,
    permission: sitePermissions.programLinkMeritList,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createProgramLinkMeritList,
    permission: sitePermissions.createProgramLinkMeritList,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.financeDashboard,
    permission: sitePermissions.financeDashboard,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.systemLogsListing,
    permission: sitePermissions.systemLogsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.systemUsersListing,
    permission: sitePermissions.systemUsersListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createSystemUsers,
    permission: sitePermissions.createSystemUsers,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.systemMenusListing,
    permission: sitePermissions.systemMenusListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createSystemMenus,
    permission: sitePermissions.createSystemMenus,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.unregisteredMenusListing,
    permission: sitePermissions.unregisteredMenusListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.userAuditListing,
    permission: sitePermissions.userAuditListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.workflowListing,
    permission: sitePermissions.workflowListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createWorkflow,
    permission: sitePermissions.createWorkflow,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createBank,
    permission: sitePermissions.createBank,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.banksListing,
    permission: sitePermissions.banksListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.bankTransactionLogsListing,
    permission: sitePermissions.bankTransactionLogsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.bankScrollLogsListing,
    permission: sitePermissions.bankScrollLogsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createVoucherParticular,
    permission: sitePermissions.createVoucherParticular,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.voucherParticularListing,
    permission: sitePermissions.voucherParticularListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.eportalCareersListing,
    permission: sitePermissions.eportalCareersListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createEportalCareer,
    permission: sitePermissions.createEportalCareer,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createVoucherType,
    permission: sitePermissions.createVoucherType,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.voucherTypesListing,
    permission: sitePermissions.voucherTypesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createVoucherTemplateHeader,
    permission: sitePermissions.createVoucherTemplateHeader,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.voucherTemplateHeaderListing,
    permission: sitePermissions.voucherTemplateHeaderListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createFinanceApplications,
    permission: sitePermissions.createFinanceApplications,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.financeApplicationsListing,
    permission: sitePermissions.financeApplicationsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createApplicationTemplateLink,
    permission: sitePermissions.createApplicationTemplateLink,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.applicationTemplateLinkListing,
    permission: sitePermissions.applicationTemplateLinkListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.studentFinancialClearanceListing,
    permission: sitePermissions.studentFinancialClearanceListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.checkFinancialChallanStatus,
    permission: sitePermissions.checkFinancialChallanStatus,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.templateProgramAccountCodeListing,
    permission: sitePermissions.templateProgramAccountCodeListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createFineSlot,
    permission: sitePermissions.createFineSlot,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.fineSlotsListing,
    permission: sitePermissions.fineSlotsListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.masterBookListing,
    permission: sitePermissions.masterBookListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.masterBookTransactionDetail,
    permission: sitePermissions.masterBookTransactionDetail,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.showMasterBookTransactionDetail,
    permission: sitePermissions.showMasterBookTransactionDetail,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.eportalAppliedJobListing,
    permission: sitePermissions.eportalAppliedJobListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.eportalCarrerProfile,
    permission: sitePermissions.eportalCarrerProfile,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.careerDesignationListing,
    permission: sitePermissions.careerDesignationListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createCareerDesignation,
    permission: sitePermissions.createCareerDesignation,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.careerAdvertisementListing,
    permission: sitePermissions.careerAdvertisementListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createCareerAdvertisement,
    permission: sitePermissions.createCareerAdvertisement,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.careerPostTemplateListing,
    permission: sitePermissions.careerPostTemplateListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createCareerPostTemplate,
    permission: sitePermissions.createCareerPostTemplate,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.careerPostListing,
    permission: sitePermissions.careerPostListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createCareerPost,
    permission: sitePermissions.createCareerPost,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.careerApplicantListing,
    permission: sitePermissions.careerApplicantListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.voucherTemplateBodiesListing,
    permission: sitePermissions.voucherTemplateBodiesListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.financeInstallmentListing,
    permission: sitePermissions.financeInstallmentListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createFinanceInstallment,
    permission: sitePermissions.createFinanceInstallment,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.installmentSlotListing,
    permission: sitePermissions.installmentSlotListing,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.createInstallmentSlot,
    permission: sitePermissions.createInstallmentSlot,
    domains: [domains.subDomain],
  },
  {
    path: siteRoutes.slotsInstallmentParticulars,
    permission: sitePermissions.slotsInstallmentParticulars,
    domains: [domains.subDomain],
  },
];
