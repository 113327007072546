import { FC, useEffect, useState } from "react";
import { QualificationTestMain, ContentWrapper, Container } from "./style";
import { CloseMediumSvg, SmallUploadSvg } from "assets/images/common/svgs";

import { AddQualificationDTO } from "utils/helpers/models/e-portal/add-qualification.dto";
import dummyAvatar from "assets/images/common/others/dummy-avatar.png";
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import { useLocation, useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

interface AddQualificationProps {
  setOpen: Function;
}

const AddPublication: FC<AddQualificationProps> = ({ setOpen }) => {
  const [formData, setFormData] = useState<AddQualificationDTO>(
    new AddQualificationDTO()
  );
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    if (state?.qualification)
      navigate(siteRoutes.ePortalEditProfile, { state: {} });
    setOpen(false);
  };

  return (
    <QualificationTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Publications </span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>DOI#</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Category</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Title/ Research Article</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Title/ Research Article" />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Journal/ Publisher Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      name=""
                      id=""
                      placeholder="Enter The Journal/ Publisher Name"
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Author Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Author Name"
                      name=""
                      id=""
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Impact Factor/ HEC Category </label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Impact Factor/ HEC Category "
                    />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Volume / No / Pages </label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Volume / No / Pages " />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Year of Publication </label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Year of Publication " />
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label>Month of Publication </label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="text" placeholder="Month of Publication " />
                  </div>
                </div>
              </div>
            </div>

            <div className="upload-field">
              <label>Uploade Bibtex File</label>
              <label className="field-wrapper">
                <div className="file-name-section">
                  <div className="inner-content">
                    <div className="upload-text">
                      <div className="upload-icon">
                        <SmallUploadSvg className="icon" />
                      </div>
                      <span className="text">Upload Bibtex File</span>
                    </div>
                    <div className="upload-restrictions">
                      Select a 300x300 jpg image with maximum size of 400 KB
                    </div>
                  </div>
                </div>
                <div className="uploaded-image">
                  <img src={dummyAvatar} alt="" />
                </div>
                <input type="file" className="d-none" />
              </label>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray" type="reset">
                  Reset
                </button>

                <button className="lg-rounded-btn black" type="submit">
                  Save & Add More
                </button>
                <button className="lg-rounded-btn" type="button">
                  Save
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </QualificationTestMain>
  );
};

export default AddPublication;
