import { FC, Fragment, useState } from "react";
import { AcademicSessionListingSection, AcademicSessionListingTop, AcademicSessionListingMain } from "./style";
import { ExcelSvg, PdfSvg, SearchFieldSvg, TableGreenPlusSvg, TableRedMinusSvg, TableYellowEditSvg } from "assets/images/common/svgs";

interface AcademicSessionListingProps { }

const AcademicSessionListing: FC<AcademicSessionListingProps> = ({ }) => {

    const columns: string[] = [
        'Semester Sequency No.',
        'Year',
        'Semester Type',
        'Enrollment Start Date',
        'Enrollment Close Date',
        'Total Courses',
        'Total Students',
        'Is Active',
        'Action',
    ];

    const [data, setData] = useState<any[]>([
        {
            isExpanded: false,
        },
        {
            isExpanded: false,
        },
        {
            isExpanded: false,
        },
        {
            isExpanded: false,
        },
    ]);

    const toggleRowExpand = (index: number) => {
        const dat = [...data];
        dat[index].isExpanded = !dat[index].isExpanded;
        setData([...dat]);
    };

    return (
        <AcademicSessionListingMain>
            <AcademicSessionListingTop>
                <div className="left">
                    <span className="page-heading">Academic Session List</span>
                </div>
                <div className="right">
                    <div className="create-org-btn">
                        <button className="lg-rounded-btn">
                            + Add Academic Session
                        </button>
                    </div>
                </div>
            </AcademicSessionListingTop>
            <AcademicSessionListingSection className="content-radius-shadow">
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        <div className="export-btn">
                            <span>
                                <ExcelSvg className="icon" />
                            </span>
                            <span className="text">Excel</span>
                        </div>
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input
                            type="search"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item: any, index: number) => {
                                return (
                                    <Fragment key={index}>
                                        <tr className={`expandable ${item.isExpanded && "opened"}`}>
                                            <td>
                                                19
                                            </td>
                                            <td>
                                                <div className="mw-150">2023</div>
                                            </td>
                                            <td>Fall</td>
                                            <td>
                                                2023-08-21
                                            </td>
                                            <td>2023-09-11</td>
                                            <td>10,440</td>
                                            <td>42,826</td>
                                            <td>Yes</td>
                                            <td>
                                                <div className="table-action-icons">
                                                    <div className="action-icon" onClick={() => toggleRowExpand(index)}>
                                                        {item?.isExpanded ? <TableRedMinusSvg /> : <TableGreenPlusSvg />}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        {item.isExpanded && (
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="expanded-content">
                                                        <div className="action-button">
                                                            <button>Management Academic Session</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <button>Faculty Workload CSV</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <button>LMS Statistics</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <button>Courses Output CSV</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <button>Courses with lab Output to CSV</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <button>Courses Prefferred Rooms Output to CSV</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <button>Course Groups</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <button className="red">Course Anomalies</button>
                                                        </div>
                                                        <div className="action-button">
                                                            <TableYellowEditSvg />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    {/* <Pagination onPageChange={onPageChange} {...pagination} /> */}
                </Fragment>
            </AcademicSessionListingSection>
        </AcademicSessionListingMain>
    )
}

export default AcademicSessionListing;