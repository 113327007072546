import {
  deleteRequest,
  getRequest,
  postRequest,
} from "utils/helpers/common/http-methods";
import { AddAdmissionApplicationDTO } from "utils/helpers/models/e-portal/add-admission-application.dto";

export const USER_APIS = {
  getProfile: () => getRequest("user/profile"),
  getCitiesByUser: (params: any) => getRequest("/cities/list", params),
  updateUserPersonalInfo: (body: any) =>
    postRequest("user/update/personal-info", body),
  updateUserGuradianInfo: (body: any) =>
    postRequest("user/update/guardian-info", body),
  updateUserAdditionalInfo: (body: any) =>
    postRequest("user/update/additional-info", body),
  createEntryTest: (body: any) => postRequest("user/create/entryTest", body),
  updateEntryTest: (id: number, body: any) =>
    postRequest(`user/update/entryTest/${id}`, body),
  getEntryTestsByUser: (params: any) =>
    getRequest(`user/list/entryTest`, params),
  createQualification: (body: any) =>
    postRequest("user/create/qualification", body),
  updateQualification: (id: number, body: any) =>
    postRequest(`user/update/qualification/${id}`, body),
  getQualificationsByUser: (params: any) =>
    getRequest(`user/qualifications/list`, params),
  getBoardsByUser: (id: string) => getRequest(`user/boards/list/${id}`),
  getCertificateLevelsByUser: (params: any) =>
    getRequest(`user/certificatelevels/list`, params),
  getDegreeCertificatesByUser: (id: string) =>
    getRequest(`user/degreecertificates/list/${id}`),
  getResultTypesByUser: (params: any) =>
    getRequest(`user/resulttypes/list`, params),
  getEntryTestByUserById: (params: any) =>
    getRequest(`user/resulttypes/list`, params),
  getQualificationByUserById: (params: any) =>
    getRequest(`user/resulttypes/list`, params),
  deleteQualificationByUser: (id: number) =>
    deleteRequest(`user/delete/qualification/${id}`),
  downloadQualificationDocumentByUser: (id: number) =>
    getRequest(`user/download/qualification/document/${id}`),
  deleteEntryTestByUser: (id: number) =>
    deleteRequest(`user/delete/entryTest/${id}`),
  getEntryTestTypesByUser: (params: any) =>
    getRequest(`user/admentrytests/list`, params),
  getStudentsApplications: (params: any) =>
    getRequest(`user/applications/list`, params),
  getStudentProgramsToApply: (params: any) =>
    getRequest(`user/admission/programs/list`, params),
  getStudentCertificateLevels: (params: any) =>
    getRequest(`user/certificatelevels/list`, params),
  getStudentDegreeCertificates: (id: number) =>
    getRequest(`user/degreecertificates/list/${id}`),
  getStudentBoards: (id: number) => getRequest(`user/boards/list/${id}`),
  getSubjectsByUser: (params: any) => getRequest(`user/subjects/list`, params),
  getAdmissionPrograms: (params: any) => getRequest(`user/admission/programs/list`, params),
  submitAdmissionApplication: (body: AddAdmissionApplicationDTO) =>
    postRequest(`user/admissions/create`, body),
  getStudentApplications: (params: any) => getRequest(`user/applications/list`),
  getCampusApplications: () => getRequest(`user/campuses`),
};
