import { FC, Fragment, useState } from "react";
import {
  StudentInfo,
  EportalViewProfileMain,
  EportalViewProfileTopSection,
  InfoField,
  InfoSection,
  BasicInfo,
  EducationalInfo,
} from "./style";
import {
  BlackTableViewSvg,
  EducationalInfoSvg,
  EmployeeInfoSvg,
  EmploymentIconSvg,
  PublicationIconSvg,
  ReferenceIconSvg,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import dummyImage from "../../../../../assets/images/common/others/download.png";
import Pagination from "components/particles/table/pagination";

interface EportalViewProfileProps {}

const EportalViewCareerProfile: FC<EportalViewProfileProps> = () => {
  const { hasAccess } = useStore();
  const [formData, setFormData] = useState<any>({});

  const columns: string[] = [
    "Sr.",
    "Certificate/Degree",
    "Institute",
    "Year",
    "Rollno",
    "Certificate No.",
    "Total Marks / CGPA",
    "Obtained Marks / GPA",
  ];
  const employment: string[] = [
    "Sr.",
    "Organization",
    "Designation/ Appointment",
    "Salary Drawn",
    "From",
    "To",
    "Duration",
    "Reasons For Leaving",
  ];
  const publications: string[] = [
    "Sr.",
    "DOI #",
    "Title / Research Article ",
    "Journal / Publisher Name ",
    "Authors Name ",
    "Impact Factor / HEC Category",
    "Volume / No. / Pages",
    "Month of Publication",
    "Year of Publication",
    "Bibtex File",
  ];
  const reference: string[] = [
    "Sr.",
    "Reference Name ",
    "Designation ",
    "Organization ",
    "Relationship ",
    "Contact",
    "Email",
  ];

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
  };

  const navigate = useNavigate();

  return (
    <EportalViewProfileMain>
      <EportalViewProfileTopSection>
        <span className="page-heading">Your Profile</span>
        {hasAccess(sitePermissions.ePortalEditProfile) && (
          <div className="edit-profile-button">
            <button
              className="lg-rounded-btn"
              onClick={() => navigate(siteRoutes.createEportalCareer)}
            >
              Edit Profile
            </button>
          </div>
        )}
      </EportalViewProfileTopSection>

      <InfoSection>
        <StudentInfo>
          <div className="profile-pic-section info-container">
            <div className="content-section">
              <div className="profile-picture">
                <img src={dummyImage} alt="" />
              </div>
              <div className="user-name">
                Mr. Muhammad Taimoor Ahmed Chughtai
              </div>
              <div className="designation">Bachelor in Computer Science</div>
            </div>
          </div>

          <div className="info-container employee-info">
            <div className="header-part">
              <div className="heading-part">
                <div className="icon-part">
                  <EmployeeInfoSvg className="icon" />
                </div>
                <span className="heading">Job Application Information</span>
              </div>

              <div className="download-profile-button">
                <button className="lg-rounded-btn">Download Profile</button>
              </div>
            </div>

            <div className="fields-section">
              <InfoField gray={false} studentInfo>
                <span className="title">Job Title:</span>
                <span className="info">Active</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Job Application No.</span>
                <span className="info">208234</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Applied Department</span>
                <span className="info">Computer Science</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Campus</span>
                <span className="info">Bahawalpur (Main Campus)</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Job Post Closing Date</span>
                <span className="info">2021-11-27</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Batch</span>
                <span className="info">IPB-263</span>
              </InfoField>
            </div>
          </div>
        </StudentInfo>
        <BasicInfo className="info-container">
          <div className="header-part">
            <div className="heading-part">
              <div className="icon-part">
                <EmployeeInfoSvg className="icon" />
              </div>
              <span className="heading">Basic Information</span>
            </div>
          </div>

          <div className="fields-section">
            <InfoField gray={false}>
              <span className="title">Father Name</span>
              <span className="info">Slah Udeen</span>
            </InfoField>
            <InfoField gray={false}>
              <span className="title">CNIC</span>
              <span className="info">31102-7852638-3</span>
            </InfoField>
            <InfoField gray={true}>
              <span className="title">Date Of Birth</span>
              <span className="info">1992-10-22</span>
            </InfoField>
            <InfoField gray={true}>
              <span className="title">Domicile</span>
              <span className="info">Rahim Yar Khan</span>
            </InfoField>
            <InfoField gray={false}>
              <span className="title">Nationality</span>
              <span className="info">Pakistan</span>
            </InfoField>
            <InfoField gray={false}>
              <span className="title">Passport No</span>
              <span className="info"></span>
            </InfoField>
            <InfoField gray={true}>
              <span className="title">Religion</span>
              <span className="info">Islam</span>
            </InfoField>
            <InfoField gray={true}>
              <span className="title">Blood Group</span>
              <span className="info"></span>
            </InfoField>
            <InfoField gray={false}>
              <span className="title">Gender</span>
              <span className="info">Male</span>
            </InfoField>
            <InfoField gray={false}>
              <span className="title">Mobile No.</span>
              <span className="info">0313-1234567</span>
            </InfoField>

            <InfoField gray={true}>
              <span className="title">Phone No</span>
              <span className="info">0324-1234567</span>
            </InfoField>

            <InfoField gray={true}>
              <span className="title">Email</span>
              <span className="info">abcd@gmail.com</span>
            </InfoField>
          </div>

          <InfoField gray={false}>
            <span className="title">Present Address</span>
            <span className="info">House No 18, Street no 2, Colony, XYZ</span>
          </InfoField>
          <InfoField gray={true}>
            <span className="title">Permanent Address</span>
            <span className="info">House No 18, Street no 2, Colony, XYZ</span>
          </InfoField>
        </BasicInfo>
        <EducationalInfo className="info-container">
          <div className="header-part">
            <div className="heading-part">
              <div className="icon-part">
                <EducationalInfoSvg className="icon" />
              </div>
              <span className="heading">Educational Information</span>
            </div>
          </div>

          <div className="data-table">
            <table className="bottom-bordered-cells no-border">
              <thead>
                <tr>
                  {columns.map((item: string, index: number) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>SSC(Matric) / O Levels Computer Science</td>
                  <td>BISE Bahawalpur</td>
                  <td>2008</td>
                  <td>0</td>
                  <td>0</td>
                  <td>850</td>
                  <td>493</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Fragment>
            <Pagination onPageChange={onPageChange} {...pagination} />
          </Fragment>
        </EducationalInfo>

        <EducationalInfo className="info-container">
          <div className="header-part">
            <div className="heading-part">
              <div className="icon-part">
                <EmploymentIconSvg className="icon" />
              </div>
              <span className="heading">Employment Information</span>
            </div>
          </div>

          <div className="data-table">
            <table className="bottom-bordered-cells no-border">
              <thead>
                <tr>
                  {employment.map((item: string, index: number) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td> Get Smart Solutions</td>
                  <td>CEO</td>
                  <td>200,000</td>
                  <td>2021-11-27</td>
                  <td>2021-11-27</td>
                  <td>1 years 9 months</td>
                  <td className="reason">Reason Here</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Fragment>
            <Pagination onPageChange={onPageChange} {...pagination} />
          </Fragment>
        </EducationalInfo>

        <EducationalInfo className="info-container">
          <div className="header-part">
            <div className="heading-part">
              <div className="icon-part">
                <PublicationIconSvg className="icon" />
              </div>
              <span className="heading">Publication Information</span>
            </div>
          </div>

          <div className="data-table">
            <table className="bottom-bordered-cells no-border">
              <thead>
                <tr>
                  {publications.map((item: string, index: number) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>022</td>
                  <td>
                    Blockchain Technology: Transforming Global Supply Chains
                  </td>
                  <td>IEEE</td>
                  <td>Alexandra Carter</td>
                  <td>5.5</td>
                  <td>522</td>
                  <td>June</td>
                  <td>2025</td>
                  <td>
                    <BlackTableViewSvg />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Fragment>
            <Pagination onPageChange={onPageChange} {...pagination} />
          </Fragment>
        </EducationalInfo>

        <EducationalInfo className="info-container">
          <div className="header-part">
            <div className="heading-part">
              <div className="icon-part">
                <ReferenceIconSvg className="icon" />
              </div>
              <span className="heading">References</span>
            </div>
          </div>

          <div className="data-table">
            <table className="bottom-bordered-cells no-border">
              <thead>
                <tr>
                  {reference.map((item: string, index: number) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Rebecca A. Clark</td>
                  <td>Chief Technology Officer (CTO)</td>
                  <td>TechWave Solutions</td>
                  <td>Sister</td>
                  <td>0300-0000000</td>
                  <td>rebecca@techwave.co</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Fragment>
            <Pagination onPageChange={onPageChange} {...pagination} />
          </Fragment>
        </EducationalInfo>
      </InfoSection>
    </EportalViewProfileMain>
  );
};

export default EportalViewCareerProfile;
