import { FC, Fragment, useEffect, useState } from "react";
import {
  DeleteTableSvg,
  DownArrowLightgrayMediumSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
  TableGreenEyeSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  ApplicantListingMain,
  ApplicantListingSection,
  ApplicantListingTop,
  FilterHeader,
  Filters,
  FilterSection,
} from "./style";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { JobDesignationDTO } from "utils/helpers/models/careers/designation.dto";
import { JobBatchDTO } from "utils/helpers/models/careers/batch.dto";
import useUtils from "hooks/useUtils";
import { useNavigate } from "react-router-dom";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import useCareers from "../useHooks";

const CareerApplicantListing: FC = () => {
  const [data, setData] = useState<any[]>([]);
  const { hasAccess } = useStore();
  const [designations, setDesignations] = useState<JobDesignationDTO[]>([]);
  const [jobBatches, setJobBatches] = useState<JobBatchDTO[]>([]);
  const [search, setSearch] = useState<string>("");
  const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const navigate = useNavigate();
  const columns: string[] = [
    "",
    "CNIC",
    "App #",
    "Batch #",
    "Name",
    "Gender",
    "IUB Employee",
    "Date applied",
    "DOB",
  ];

  const { handleSearchChange, handleTableSearch } = useUtils();
  const { getCareerApplications, getJobDesignations, getJobBatches } = useCareers();


  useEffect(() => {
    getAllCareerApplications(pagination.page, search);
    getJobDesignations(setDesignations);
    getJobBatches(setJobBatches)

  }, []);

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      // deleteCareerApplication(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllCareerApplications(page + 1, search);
  };
  const getAllCareerApplications = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    getCareerApplications(setData, queryParams, setPagination);
  };

  const toggleRowExpand = (index: number) => {
    data[index].isExpanded = !data[index].isExpanded;
    setData([...data]);
  };

  const toggleFilterDropdown = () => {
    setOpenFilterDropdown(!openFilterDropdown);
  };

  return (
    <ApplicantListingMain>
      <ApplicantListingTop>
        <div className="left">
          <span className="page-heading">Applicants</span>
          <Breadcrumb />
        </div>
        <div className="right">
          <div className="create-org-btn"></div>
        </div>
      </ApplicantListingTop>

      <FilterSection className="content-radius-shadow">
        <FilterHeader showFilterDropdown={openFilterDropdown}>
          <span className="filter-heading">Filter</span>
          <span className="dropdown-arrow cp" onClick={toggleFilterDropdown}>
            <DownArrowLightgrayMediumSvg className="icon" />
          </span>
        </FilterHeader>
        {openFilterDropdown && (
          <Filters>
            <div className="filter-fields">
              <div className="input-field">
                <label>Designation</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Designation</option>
                      {designations.map((designation, index) => {
                        return <option value={designation.id} key={index}>{designation.title}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> Select Department</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Department</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> Select Challan Status </label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> Select Batch</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select Batch</option>
                      {jobBatches.map((batch, index) => {
                        return <option value={batch.id} key={index}></option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> Select Campus</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Campus</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> Select Job Status</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> Disable</label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> Religion </label>
                <div className="field-wrap">
                  <div className="field">
                    <select>
                      <option value=""> Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-field">
                <label> CNIC</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="number" />
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-buttons">
              <div className="buttons">
                <button className="lg-rounded-btn gray">Reset</button>
                <button className="lg-rounded-btn">Apply Filters</button>
              </div>
            </div>
          </Filters>
        )}
      </FilterSection>
      <ApplicantListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadStudentRegPDF) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadStudentRegExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, getAllCareerApplications)
              }
              onKeyUp={(e) => handleTableSearch(e, getAllCareerApplications)}
            />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr className={`expandable ${item.isExpanded && "opened"}`}>
                      <td>
                        <div
                          className="rounded-expand-button"
                          onClick={() => toggleRowExpand(index)}
                        >
                          <span>{item.isExpanded ? "-" : "+"}</span>
                        </div>
                      </td>
                      <td>
                        <div>53404-9227961-5</div>
                      </td>
                      <td>
                        <div className="mw-150">1234</div>
                      </td>
                      <td>
                        <div className="mw-100">21-25</div>
                      </td>
                      <td>
                        <div className="mw-150">Imran</div>
                      </td>
                      <td>Male</td>
                      <td>
                        <div className="mw-100">Yes</div>
                      </td>
                      <td>
                        <div className="mw-150">2024-02-18</div>
                      </td>
                      <td>2430413105</td>
                    </tr>

                    {item.isExpanded && (
                      <tr>
                        <td></td>
                        <td colSpan={7}>
                          <div className="expanded-content">
                            <div className="particular-info">
                              <span className="title">Job Position</span>
                              <span className="info">--</span>
                            </div>
                            <div className="particular-info">
                              <span className="title">Father Name:</span>
                              <span className="info">--</span>
                            </div>
                            <div className="particular-info">
                              <span className="title">Mobile:</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Email:</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Domicile:</span>
                              <div className="info">
                                <span className="status">--</span>
                              </div>
                            </div>
                            <div className="particular-info">
                              <span className="title">Action</span>
                              <div className="info">
                                <div className="table-action-icons">
                                  <div className="action-icon">
                                    <TableGreenEyeSvg />
                                  </div>
                                  <div className="action-icon">
                                    <EditTableSvg />
                                  </div>

                                  <div className="action-icon">
                                    <DeleteTableSvg />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination
            onPageChange={onPageChange}
            {...pagination}
          />
        </Fragment>
      </ApplicantListingSection>
    </ApplicantListingMain>
  );
};

export default CareerApplicantListing;
